import { FC, useEffect, useState } from 'react';

import HeaderDivisionsMenu from '@/Components/header/header-divisions-menu/header-divisions-menu';
import HeaderDestinationsMenu from '@/Components/header/header-destinations-menu/header-destinations-menu';
import HeaderWhyUsMenu from '@/Components/header/header-why-us-menu/header-why-us-menu';
import HeaderAboutMenu from '@/Components/header/header-about-menu/header-about-menu';
import { useRouter } from 'next/router';
import getMenus from '@/Components/header/header-destinations-menu/helpers';

type Props = {
  className?: string;
  navigationData: any;
};

const NavigateLinks: FC<Props> = ({ className, navigationData }) => {
  const [nav, setNav] = useState<string | null>(null);

  const result = [...navigationData.data.regions].map((r: any) => {
    let resgoin = { ...r };
    resgoin.destinations = resgoin.destinations.filter((d: any) => d.count > 0);
    return resgoin;
  });
  const regionsMenuData = getMenus(result);

  const router = useRouter();

  useEffect(() => {
    setNav(null);

    router.events.on('routeChangeStart', () => {
      setNav(null);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const links = ['What', 'Where', 'Why', 'How'];

  const navChangeHandler = (navName: string | null) => {
    setNav(navName);
  };

  return (
    <ul className={`${className}`} onMouseLeave={() => navChangeHandler(null)}>
      {links.map((link) => (
        <li
          key={link}
          className="font-semibold px-4 py-4 flex items-center cursor-pointer text-dark-900"
          onClick={() => navChangeHandler(link)}
          onMouseOver={() => navChangeHandler(link)}
        >
          {link}
          <i className="icon-down text-3xs pl-2" />
        </li>
      ))}
      <div
        className={
          'absolute left-0 bottom-0 w-screen h-fit bg-white top-full cursor-default border-t z-100 shadow-nav-menu' +
          (nav ? ' block' : ' hidden')
        }
      >
        <HeaderDivisionsMenu navigationData={navigationData} activeMenu={nav} />
        <HeaderDestinationsMenu
          navigationData={regionsMenuData}
          activeMenu={nav}
        />
        <HeaderWhyUsMenu activeMenu={nav} />
        <HeaderAboutMenu activeMenu={nav} />
      </div>
    </ul>
  );
};

export default NavigateLinks;
