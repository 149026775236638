import { TCurrency } from '../types/currency';
import { UserLocation } from '../types/location';

export const getCurrencyByLocation = (location: UserLocation): TCurrency => {
  if (location.country.code === 'GB') return 'GBP';
  if (location.country.code === 'NZ') return 'NZD';
  if (location.country.code === 'AU') return 'AUD';
  if (location.country.code === 'CA') return 'CAD';

  if (location.region === 'South West Asia') return 'EUR';
  if (location.continent === 'Europe') return 'EUR';
  if (location.continent === 'Africa') return 'EUR';

  if (location.continent === 'Americas') return 'USD';

  if (location.continent === 'Asia') return 'AUD';
  if (location.continent === 'Oceania') return 'AUD';

  return 'USD';
};
