import { useLocation } from '@/Lib/hooks/useLocation';
import React, { useEffect, useState } from 'react';
import { GImage } from '../g-image';
import Link from 'next/link';
import dayjs from 'dayjs';
import { useRouter } from 'next/router';
import { useAtomValue } from 'jotai';
import {
  userEmailAtom,
  userLoginStatusAtom,
} from '@/State/global/global.store';
import { Login } from '../auth0/login';
import { SuccessAppToastIcon } from '../toast/app-toast-icon-set';
import { RudderAnalytics } from '../ruddarstak/rudderanalytics';

const Footer = () => {
  const isUserLoggedIn = useAtomValue(userLoginStatusAtom);
  const userEmail = useAtomValue(userEmailAtom);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [showMessage, setShowMessage] = useState(false);
  const { location } = useLocation();
  const currentYear = dayjs().year();
  const router = useRouter();
  const [hasExtraBottomMargin, setHasExtraBottomMargin] =
    useState<boolean>(false);
  const logos =
    location.continent === 'Americas'
      ? ['consumer-protection-bc']
      : location.continent === 'Europe'
      ? ['atol', 'abta']
      : [];

  const checkLogin = () => {
    if (isUserLoggedIn) {
      rudderStackEvent();
      setShowMessage(true);
    } else {
      setIsModalOpen(true);
    }
  };

  const rudderStackEvent = () => {
    RudderAnalytics.newsletterSubscribed({
      country: location.country.name,
      email: userEmail || '',
      platform: 'website',
    });
  };

  useEffect(() => {
    if (router.route === '/[division]/[...trip]') {
      setHasExtraBottomMargin(true);
    } else {
      setHasExtraBottomMargin(false);
    }
  }, [router.route]);

  return (
    <>
      <Login
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        AfterLogin={() => {
          setShowMessage(true);
        }}
        type="subscribe-init-form"
      />
      <footer
        className={
          'items-center pt-12 border-t border-grey block ' +
          (hasExtraBottomMargin ? 'mb-[7.187rem] md:mb-0' : '')
        }
      >
        <div className="max-w-7xl m-auto">
          <div className="grid md:grid-cols-footer gap-8 xl:mx-10 md:mx-7">
            <div className="md:text-left md:m-0 text-center max-w-22rem justify-self-center self-center">
              <h3 className="text-xl leading-6 font-semibold mb-1">
                Want to know a secret or two?
              </h3>
              <p className="text-base mb-2 text-dark-600 leading-5">
                Join our inner circle of over 1,000,000 to find out the tips &
                tricks of the better way to travel.
              </p>

              <button
                className={
                  ' text-light-600 px-8 py-2.5 text-xl font-semibold rounded-md ' +
                  (showMessage ? ' bg-dark-500' : 'bg-teal-900')
                }
                onClick={() => checkLogin()}
              >
                Subscribe
              </button>
              {showMessage && (
                <div className="mt-[1.125rem] flex justify-center md:justify-start">
                  <SuccessAppToastIcon stroke="#0083A0" className="w-5 h-5" />
                  <div className="ml-[0.625rem] text-base !leading-5 font-semibold text-teal-900">
                    You are subscribed now
                  </div>
                </div>
              )}

              <div className="md:text-left text-center mt-8">
                <a
                  aria-label="instagram"
                  href="https://www.instagram.com/globalworkandtravel/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="icon-instagram text-xl text-dark-700 mr-5"></i>
                </a>
                <a
                  aria-label="facebook"
                  href="https://www.facebook.com/globalworkandtravel"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="icon-facebook1 text-xl text-dark-700 mr-5"></i>
                </a>
                <a
                  aria-label="tiktok"
                  href="https://www.tiktok.com/@globalworkandtravel"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="icon-tik-tok text-xl text-dark-700 mr-5"></i>
                </a>
                <a
                  aria-label="youtube"
                  href="https://www.youtube.com/user/gwatsup"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="icon-youtube text-xl text-dark-700 mr-5"></i>
                </a>
                <a
                  aria-label="twitter"
                  href="https://twitter.com/TheGWATCo"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="icon-twitter text-xl text-dark-700 mr-5"></i>
                </a>
                <a
                  aria-label="pinterest"
                  href="https://pinterest.com/thegwatco/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="icon-pinterest text-xl text-dark-700 mr-5"></i>
                </a>
                <a
                  aria-label="linkedin"
                  href="https://www.linkedin.com/company/the-global-work-&-travel-co-"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="icon-linkedin text-xl text-dark-700 mr-5"></i>
                </a>
              </div>
            </div>

            <div className="justify-self-center self-center">
              <a
                href="https://www.iata.org/"
                target="_blank"
                rel="noreferrer"
                aria-label="iata"
              >
                <div className="md:w-28 w-20">
                  <GImage
                    path="website/logo/iata-logo.svg"
                    alt="Iata"
                    height="auto"
                    width="100%"
                    classes=""
                  />
                </div>
              </a>
            </div>
            <div className=" grid grid-cols-9-10 xl:grid-cols-2-1fr md:grid-cols-5.5-10 xl:max-w-22rem justify-center justify-self-center self-centers gap-x-5 w-full">
              <Link href="/parents">
                <a className="my-2.5 text-dark-700 cursor-pointer text-base leading-6">
                  Parents
                </a>
              </Link>
              <Link href="/about-us">
                <a className="my-2.5 text-dark-700 cursor-pointer text-base leading-6">
                  About us
                </a>
              </Link>
              <Link href="/partners">
                <a className="my-2.5 text-dark-700 cursor-pointer text-base leading-6">
                  Partners
                </a>
              </Link>
              <Link href="/contact">
                <a className="my-2.5 text-dark-700 cursor-pointer text-base leading-6">
                  Contact us
                </a>
              </Link>
              <Link href="/careers">
                <a className="my-2.5 text-dark-700 cursor-pointer text-base leading-6">
                  Careers
                </a>
              </Link>
              <Link href="/terms-and-conditions?tab=bc">
                <a className="my-2.5 text-dark-700 cursor-pointer text-base leading-6">
                  Booking conditions
                </a>
              </Link>
              <Link href="/affiliate">
                <a className="my-2.5 text-dark-700 cursor-pointer text-base leading-6">
                  Affiliates
                </a>
              </Link>
              <Link href="/terms-and-conditions?tab=pp">
                <a className="my-2.5 text-dark-700 cursor-pointer text-base leading-6">
                  Privacy policy
                </a>
              </Link>
            </div>
          </div>
        </div>
        <div className="bg-light-700 mt-5 py-5 text-center">
          <div className="text-base leading-6 font-bold text-dark-800">
            The Global Work & Travel Group
          </div>
          <div className="text-sm font-normal leading-5 pt-2 px-3 pb-0.5 text-dark-700">
            Proudly part of the Global Work & Travel family of brands, a
            worldwide leader in life-changing travel & technology.
          </div>
          <div className="flex justify-center items-end">
            <div className="box-border w-12 lg:w-20 mt-2 mx-2">
              <GImage
                path="website/logo/global-work-and-travel-logo.svg"
                alt="global work and travel logo"
                height="auto"
                width="100%"
                classes="!object-contain"
              />
            </div>
            <div className="w-20 lg:w-32 mt-2 mx-2">
              <a href="http://dealsaway.com/" target="_blank" rel="noreferrer">
                <GImage
                  path="website/logo/dealsaway-logo.svg"
                  alt="dealsaway logo"
                  height="auto"
                  width="100%"
                  classes="!object-contain"
                />
              </a>
            </div>
            <div className="w-12 lg:w-20 mt-2 mx-2">
              <a
                href="https://globaltravelcover.com"
                target="_blank"
                rel="noreferrer"
              >
                <GImage
                  path="new-website/static/homepage/global-travel-cover/gtc-logo.svg"
                  alt="global travel cover logo"
                  height="auto"
                  width="100%"
                  classes="!object-contain"
                />
              </a>
            </div>
            <div className="w-12 lg:w-20 mt-2 mx-2">
              <Link href="/global-animal-welfare-fund">
                <a>
                  <GImage
                    path="website/logo/global-animal-welfare-fund.png"
                    alt="global animal welfare fund logo"
                    height="auto"
                    width="100%"
                    classes="!object-contain"
                  />
                </a>
              </Link>
            </div>
            <div className="w-5 lg:w-8 mt-2 mx-2">
              <a
                href="https://rescuepawsthailand.org"
                target="_blank"
                rel="noreferrer"
              >
                <GImage
                  path="website/logo/rescue-paws-transparent.png"
                  alt="rescue paws logo"
                  height="auto"
                  width="100%"
                  classes="!object-contain"
                />
              </a>
            </div>
          </div>
        </div>
        <div className="py-5 text-dark-800 w-full center items-center justify-center text-sm leading-5">
          <div className="flex justify-center flex-col items-center md:flex-row gap-1">
            <span>Copyright © {currentYear} The Global Work & Travel Co.</span>
            <span>All rights reserved.</span>
          </div>
          {logos.length !== 0 ? (
            <Link href="/financial-security">
              <a>
                <div className="flex h-10 mt-5 justify-center ">
                  {logos.map((logo, index) => (
                    <div className="h-full mx-2.5" key={index}>
                      <GImage
                        width="auto"
                        height="100%"
                        path={`website/icons/footer/${logo}.svg`}
                        alt={`${logo} logo`}
                        classes="!object-contain"
                      />
                    </div>
                  ))}
                </div>
              </a>
            </Link>
          ) : null}
        </div>
      </footer>
    </>
  );
};
export default Footer;
