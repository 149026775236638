import NextHead from 'next/head';
import { RudderstackHelper } from '@/Lib/helpers/rudderstack/rudderstack';

const Head = () => {
  const customCSS = '.async-hide { opacity: 0 !important}';
  const config = RudderstackHelper.getRudderstackConfig();

  return (
    <NextHead>
      <script
        async
        src="https://www.googleoptimize.com/optimize.js?id=GTM-53X3HZP"
      ></script>
      <style>{customCSS}</style>
      <script
        dangerouslySetInnerHTML={{
          __html: `(function(a,s,y,n,c,h,i,d,e){s.className+=' '+y;h.start=1*new Date;
  h.end=i=function(){s.className=s.className.replace(RegExp(' ?'+y),'')};
  (a[n]=a[n]||[]).hide=h;setTimeout(function(){i();h.end=null},c);h.timeout=c;
})(window,document.documentElement,'async-hide','dataLayer',4000,
  {'GTM-53X3HZP':true});`,
        }}
      />
      <link rel="icon" type="image/x-icon" href="/favicon.ico" />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/static/images/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/static/images/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/static/images/favicon-16x16.png"
      />
      <link rel="manifest" href="/static/images/site.webmanifest" />

      <link rel="preconnect" href="https://res.cloudinary.com" />
      <link
        rel="stylesheet"
        href="https://dvna932ue33yp.cloudfront.net/website/fonts/gwat/gwat-fonts.css?kqmcdp"
      />
      <script
        async
        src="https://cdn.rudderlabs.com/rudder-analytics.min.js"
      ></script>
      <script
        dangerouslySetInnerHTML={{
          __html: `rudderanalytics=window.rudderanalytics=[];for(var methods=["load","page","track","identify","alias","group","ready","reset","getAnonymousId","setAnonymousId"],i=0;i<methods.length;i++){var method=methods[i];rudderanalytics[method]=function(a){return function(){rudderanalytics.push([a].concat(Array.prototype.slice.call(arguments)))}}(method)}rudderanalytics.load("${config.writeKey}","${config.url}");`,
        }}
      />
    </NextHead>
  );
};

export { Head };
