class SessionStorageService {
  key: string;

  constructor(key: string) {
    this.key = key;
  }

  set(value: string) {
    if (typeof window === 'undefined') return;
    sessionStorage.setItem(this.key, value);
  }

  get() {
    if (typeof window === 'undefined') return;
    return sessionStorage.getItem(this.key) || '';
  }

  remove() {
    if (typeof window === 'undefined') return;
    sessionStorage.removeItem(this.key);
  }
}

export { SessionStorageService };
