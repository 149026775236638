export type Stringifiable = { toString(): string };
export class LocalStorageService<T extends Stringifiable> {
  constructor(protected key: string) {
  }

  setItem(value:T) {
    return localStorage.setItem(this.key, JSON.stringify(value));
  }

  getItem() {
    return this.key ? localStorage.getItem(this.key) : null;
  }

  getFormatted(): T | null {
    // In subclassed we can implement formatter which converts the string from getItem to the required type.
    throw new Error('Not implemented');
  }

  removeItem() {
    return localStorage.removeItem(this.key)
  }
}
