import React, { FC } from 'react';

import styles from './header-menu-main.module.css';
import GlobalLogo from '@/Components/header/global-logo';
import SearchInput from '@/Components/header/search-input/search-input';
import GworldMenu from '@/Components/header/gwat-menu/gworld-menu';
import HeaderMenuMobile from '@/Components/header/header-menu-mobile/header-menu-mobile';
import NavigateLinks from '@/Components/header/navigate-links/navigate-links';
import { useDevice } from '@/Lib/hooks/useDevice';

type Props = {
  navigationData: any;
  headerHeight: number;
};

const HeaderMenuMain = ({ navigationData, headerHeight }: Props) => {
  const { isLG } = useDevice();

  return (
    <div className="relative py-2 lg:py-0 bg-white border-solid border-lightgrey border-t-0.25 border-b-0.25 pointer-events-none">
      <div className="max-w-6.75xl m-auto px-5 lg:px-10 flex flex-row items-center justify-between lg:justify-start">
        <GlobalLogo />
        <div className="flex-1 flex justify-end items-center">
          <nav className="flex self-stretch">
            <NavigateLinks
              className="hidden lg:flex mr-11 pointer-events-auto self-stretch"
              navigationData={navigationData}
            />
          </nav>
          <SearchInput
            classes={`hidden lg:flex lg:visible w-80 pointer-events-auto pl-8 mr-0 ${styles.search}`}
          />
          {isLG ? (
            <GworldMenu className="hidden lg:flex pointer-events-auto" />
          ) : null}

          <HeaderMenuMobile
            navigationData={navigationData}
            headerHeight={headerHeight}
          />
        </div>
      </div>
    </div>
  );
};

export default HeaderMenuMain;
