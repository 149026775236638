import { SocialButtons } from '../form/socialButtons';

type Props = {
  loginWith: any;
};

const LoginSocial = ({ loginWith }: Props) => {
  return (
    <div>
      <div className="px-4 pb-4 md:px-6 md:pb-6">
        <div className="flex justify-between items-center mt-4 md:mt-6 ">
          <SocialButtons brand="apple" loginWith={loginWith} />
          <SocialButtons brand="google" loginWith={loginWith} />
          <SocialButtons brand="facebook" loginWith={loginWith} />
        </div>
      </div>
    </div>
  );
};
export { LoginSocial };
