import { ToastContainer, toast } from 'react-toastify';
import { AppToastIcon } from './app-toast-icon';
import { CloseAppToastIcon } from './app-toast-icon-set';

export function ToastError(nodeOrMsg: any, isMobile: boolean) {
  return Content('error', nodeOrMsg, isMobile);
}

export function ToastSuccess(nodeOrMsg: any, isMobile: boolean) {
  return Content('success', nodeOrMsg, isMobile);
}

export function ToastInfo(nodeOrMsg: any, isMobile: boolean) {
  return Content('info', nodeOrMsg, isMobile);
}

export function ToastNotification(nodeOrMsg: any, isMobile: boolean) {
  return Content('info', nodeOrMsg, isMobile);
}

const Content = (
  type: 'success' | 'error' | 'info' | 'warning' | 'default',
  nodeOrMsg: any,
  isMobile: boolean
) => {
  return toast(
    <div className="flex  justify-between relative p-4 h-auto">
      <div className="flex">
        <div className="flex justify-center items-center">
          <AppToastIcon type={type} className="w-8 h-8" />
        </div>
        <div className="flex justify-center items-center ml-1 sm:pr-1 pr-1">
          <div className="font-semibold sm:text-sm text-xs sm:leading-4">
            {nodeOrMsg}
          </div>
        </div>
      </div>
      <div className="absolute top-2 right-2">
        <CloseAppToastIcon />
      </div>
    </div>,
    {
      autoClose: 5000,
      hideProgressBar: true,
      position: isMobile ? 'bottom-center' : 'top-right',
    }
  );
};

const Warning = () => {
  return (
    <>
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 3.75C7.44321 3.75 3.75 7.44321 3.75 12C3.75 16.5568 7.44321 20.25 12 20.25C16.5568 20.25 20.25 16.5568 20.25 12C20.25 7.44321 16.5568 3.75 12 3.75ZM12 21.75C6.61479 21.75 2.25 17.3852 2.25 12C2.25 6.61479 6.61479 2.25 12 2.25C17.3852 2.25 21.75 6.61479 21.75 12C21.75 17.3852 17.3852 21.75 12 21.75Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 6.75C12.4142 6.75 12.75 7.08579 12.75 7.5V12.5C12.75 12.9142 12.4142 13.25 12 13.25C11.5858 13.25 11.25 12.9142 11.25 12.5V7.5C11.25 7.08579 11.5858 6.75 12 6.75Z"
          fill="white"
        />
        <path
          d="M13 16.25C13 16.8023 12.5523 17.25 12 17.25C11.4477 17.25 11 16.8023 11 16.25C11 15.6977 11.4477 15.25 12 15.25C12.5523 15.25 13 15.6977 13 16.25Z"
          fill="white"
        />
      </svg>
    </>
  );
};
const Success = () => {
  return (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.46967 11.4697C8.76256 11.1768 9.23744 11.1768 9.53033 11.4697L11.7803 13.7197C12.0732 14.0126 12.0732 14.4874 11.7803 14.7803C11.4874 15.0732 11.0126 15.0732 10.7197 14.7803L8.46967 12.5303C8.17678 12.2374 8.17678 11.7626 8.46967 11.4697Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5303 9.96967C15.8232 10.2626 15.8232 10.7374 15.5303 11.0303L11.7803 14.7803C11.4874 15.0732 11.0126 15.0732 10.7197 14.7803C10.4268 14.4874 10.4268 14.0126 10.7197 13.7197L14.4697 9.96967C14.7626 9.67678 15.2374 9.67678 15.5303 9.96967Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 3.75C7.44321 3.75 3.75 7.44321 3.75 12C3.75 16.5568 7.44321 20.25 12 20.25C16.5568 20.25 20.25 16.5568 20.25 12C20.25 7.44321 16.5568 3.75 12 3.75ZM12 21.75C6.61479 21.75 2.25 17.3852 2.25 12C2.25 6.61479 6.61479 2.25 12 2.25C17.3852 2.25 21.75 6.61479 21.75 12C21.75 17.3852 17.3852 21.75 12 21.75Z"
        fill="white"
      />
    </svg>
  );
};
const Notif = () => {
  return (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 3.75C7.44321 3.75 3.75 7.44321 3.75 12C3.75 16.5568 7.44321 20.25 12 20.25C16.5568 20.25 20.25 16.5568 20.25 12C20.25 7.44321 16.5568 3.75 12 3.75ZM2.25 12C2.25 6.61479 6.61479 2.25 12 2.25C17.3852 2.25 21.75 6.61479 21.75 12C21.75 17.3852 17.3852 21.75 12 21.75C6.61479 21.75 2.25 17.3852 2.25 12Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.25 12C10.25 11.5858 10.5858 11.25 11 11.25H12C12.4142 11.25 12.75 11.5858 12.75 12V17C12.75 17.4142 12.4142 17.75 12 17.75C11.5858 17.75 11.25 17.4142 11.25 17V12.75H11C10.5858 12.75 10.25 12.4142 10.25 12Z"
        fill="white"
      />
      <path
        d="M12.75 8.25C12.75 8.80228 12.3023 9.25 11.75 9.25C11.1977 9.25 10.75 8.80228 10.75 8.25C10.75 7.69772 11.1977 7.25 11.75 7.25C12.3023 7.25 12.75 7.69772 12.75 8.25Z"
        fill="white"
      />
    </svg>
  );
};
const Info = () => {
  return (
    <svg viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0046 3.74646C13.6753 3.74477 15.2782 4.40716 16.4604 5.5878C17.6426 6.76854 18.307 8.37077 18.3073 10.0416V13.4759L19.3458 14.5151L19.3467 14.516C19.8652 15.0323 20.1572 15.7337 20.1581 16.4655L19.4081 16.4665L20.1581 16.4688C20.1535 17.9295 18.968 19.1112 17.5073 19.1112L6.51072 19.1082V19.1082C5.05008 19.1081 3.86454 17.9265 3.85993 16.4658L4.60992 16.4635L3.85992 16.462C3.86135 15.7302 4.15364 15.0291 4.67237 14.513L4.67351 14.5119L5.71169 13.4728V10.0418C5.71232 6.56633 8.5292 3.74857 12.0046 3.74646V3.74646ZM12.0054 5.24646C9.35788 5.2479 7.21224 7.39426 7.21169 10.0418V10.0418V13.7833C7.21169 13.9821 7.13276 14.1728 6.99223 14.3135L5.73271 15.574L5.73083 15.5759C5.49448 15.8107 5.36108 16.1298 5.35993 16.463C5.36295 17.0963 5.8772 17.6082 6.51071 17.6082L17.5073 17.6112V17.6112C18.1408 17.6111 18.6552 17.099 18.6581 16.4657C18.6572 16.1326 18.5241 15.8135 18.2879 15.5786L18.2863 15.577L17.0268 14.3165C16.8863 14.1758 16.8073 13.9851 16.8073 13.7863V10.0419V10.0419C16.8071 8.76915 16.3009 7.54854 15.4004 6.64913C14.4998 5.74969 13.2782 5.24509 12.0054 5.24646Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.77539 21.5035C9.77539 21.0893 10.1112 20.7535 10.5254 20.7535H13.4836C13.8978 20.7535 14.2336 21.0893 14.2336 21.5035C14.2336 21.9178 13.8978 22.2535 13.4836 22.2535H10.5254C10.1112 22.2535 9.77539 21.9178 9.77539 21.5035Z"
        fill="white"
      />
    </svg>
  );
};
