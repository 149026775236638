// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

const getEnv = () => {
  if (process.env.NODE_ENV === 'development') return 'local';
  if (process.env.NEXT_PUBLIC_ENVIRONMENT !== 'live') return 'development';
  return 'live';
};

Sentry.init({
  environment: getEnv(),
  beforeSend: (event, hint) => {
    event.extra = { ...event.extra, message: hint.originalException };
    return event;
  },
  ignoreErrors: [
    'Non-Error exception captured',
    'AbortError',
    "ReferenceError: Can't find variable: _AutofillCallbackHandler",
    'TypeError: Failed to fetch',
  ],
  ignoreUrls: [
    /extensions\//,
    /^chrome:\/\//i,
    /^moz-extension:\/\//i,
    /^chrome-extension:\/\//i,
    /script\.hotjar.com/,
  ],
  dsn:
    SENTRY_DSN ||
    'https://54ca5817116d425c89b47cfe11d48ca5@o32955.ingest.sentry.io/1766624',
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1.0,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
