import { FC } from 'react';
import { GImage } from '../g-image';
import { getInternalName } from '@/Lib/helpers/get-internal-name';
import Link from 'next/link';
import { CSSTransition } from 'react-transition-group';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime);
import { redirects } from '../../lib/configs/redirects';
import { Cross } from '@/Assets/icons/cross';
import { useNotification } from './useNotification';

const getRedirectedLink = (link: string): string => {
  let redirectedLink = link;

  redirects.forEach((redirect) => {
    if (redirect.source === link) {
      return (redirectedLink = redirect.destination);
    }
  });

  return redirectedLink;
};

const SocialProof: FC = () => {
  const { isShown, data, neverShowAgain } = useNotification();

  return data ? (
    <CSSTransition in={isShown} timeout={300} classNames="popup" unmountOnExit>
      <div
        className="fixed rounded-md overflow-hidden min-w-21.06rem max-w-[21.25rem] max-h-7.5rem top-2.5 left-1/2 -translate-x-1/2 md:translate-x-0 md:left-8 md:bottom-8 md:top-auto z-400 bg-light-600"
        style={{
          boxShadow:
            '0 0 1px rgb(9 30 66 / 31%), 0 20px 32px -8px rgb(9 30 66 / 25%)',
        }}
      >
        <div className="flex items-center gap-3 p-3 md:p-4">
          <div
            className={
              'block min-w-1.75 md:min-w-[3rem] md:h-full ml-2 md:ml-0' +
              (data.type === 'enquiry' ? ' h-7.5' : ' h-7')
            }
          >
            <GImage
              alt="social proof icon"
              width="100%"
              height="100%"
              classes="!object-contain"
              path={
                data.type === 'enquiry'
                  ? 'website/icons/get-in-touch/give-us-a-ring.svg'
                  : 'website/icons/booked.svg'
              }
            />
          </div>
          <Link
            href={
              data.link
                ? getRedirectedLink(data.link)
                : getInternalName(data.division)
            }
          >
            <a className="flex-grow">
              <div className="">
                <p className="text-sm leading-4">
                  {data.first_name}{' '}
                  {data.country ? <span>from {data.country}</span> : null} just{' '}
                  {data.type === 'enquiry' ? (
                    <span>enquired about</span>
                  ) : (
                    <span>booked</span>
                  )}{' '}
                  a{' '}
                  <b>
                    {data.division} trip{' '}
                    {data.destination ? (
                      <span>
                        to{' '}
                        <span className="capitalize">{data.destination}</span>
                      </span>
                    ) : null}
                  </b>
                </p>
              </div>
            </a>
          </Link>
          <div
            className="min-w-[1.8rem] min-h-[1.8rem] md:min-w-[2.3rem] md:min-h-[2.3rem] text-center cursor-pointer"
            onClick={() => {
              neverShowAgain();
            }}
          >
            <Cross color="#575B63" />
          </div>
        </div>
      </div>
    </CSSTransition>
  ) : null;
};

export { SocialProof };
