import { LoginTemplateData } from '@/Lib/types/login';
import { Tabs } from '../login';

type Props = {
  tab: Tabs;
  tabData: LoginTemplateData | null;
  type: string;
};

const defaultDescription = (tab: Tabs) => {
  switch (tab) {
    case Tabs.init:
      return 'Join over 1 million others as a free member of our global travel community, and we’ll let you in on the all the secrets of successfully working overseas.';
      break;

    case Tabs.login:
      return '';
      break;

    case Tabs.register:
      return 'Join over 1 million others as a free member of our global travel community, and we’ll let you in on the all the secrets of successfully working overseas.';
      break;

    case Tabs.forgetPass:
      return '';
      break;

    case Tabs.loginSocial:
      return 'Join over 1 million others as a free member of our global travel community, and we’ll let you in on the all the secrets of successfully working overseas.';
      break;

    default:
      break;
  }
};

const FormSubTitle = ({ tab, tabData, type }: Props) => {
  const returnTabData = (tab: string) => {
    const data = tabData?.loginTemplateCollection.items.filter(
      (item) => item.type === (tab === Tabs.init ? type : tab)
    );
    if (data) {
      return data[0];
    } else {
      return null;
    }
  };

  return returnTabData(tab)?.description || defaultDescription(tab) ? (
    <div className=" mt-2 md:mt-3 text-2xs md:text-sm mb-4 md:mb-6 font-normal text-dark-700 text-center !leading-4">
      {returnTabData(tab)?.description || defaultDescription(tab)}
    </div>
  ) : (
    <></>
  );
};
export { FormSubTitle };
