import React from 'react';
import { useOffice } from '@/Lib/hooks/useOffice';
import { PhoneBox } from './phone-box';
import { AvatarIcon } from './avatar-icon';

type Props = {
  className?: string;
  forceShowText?: boolean;
  forceShowAvatar?: boolean;
  hasRightPadding?: boolean;
};

const CallButton = ({
  className,
  forceShowText = false,
  forceShowAvatar = false,
  hasRightPadding = false,
}: Props) => {
  const { isOpen, phone } = useOffice();

  return (
    <div
      className={
        `flex items-center relative cursor-pointer` +
        (hasRightPadding ? ' pr-2.5' : '') +
        (className ? ` ${className}` : '')
      }
    >
      {isOpen && !forceShowAvatar ? (
        <PhoneBox phone={phone} />
      ) : (
        <AvatarIcon forceShowText={forceShowText} />
      )}
    </div>
  );
};

export default CallButton;
