import React, { useState, useRef, FC, useEffect } from 'react';

import classes from './header-menu-mobile.module.css';

import CallButton from '../call-button/call-button';
import GworldMenu from '@/Components/header/gwat-menu/gworld-menu';
import WishlistMenu from '@/Components/header/wishlist-menu';
import MobileSideNav from '@/Components/mobile-side-nav/mobile-side-nav';
import { useRouter } from 'next/router';
import { useOffice } from '@/Lib/hooks/useOffice';

type Props = {
  headerHeight: number;
  navigationData: any;
};

const HeaderMenuMobile: FC<Props> = ({ headerHeight, navigationData }) => {
  const router = useRouter();
  const [isOpen, setIsOpen] = useState(false);
  const { isOpen: isOfficeOpen } = useOffice();

  useEffect(() => {
    router.events.on('routeChangeStart', () => {
      setIsOpen(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const menuClasses = `${classes.withSeparator} ${isOpen ? 'is-active' : ''}`;

  const handleMenuToggle = (state: boolean) => {
    setIsOpen(state);
  };

  return (
    <div
      className={`lg:hidden h-full flex items-center pointer-events-auto ${classes.grid} lg:hidden flex`}
    >
      {!isOfficeOpen ? (
        <>
          <WishlistMenu className="flex items-center text-xl" />
          <GworldMenu className="flex lg:hidden" />
        </>
      ) : null}
      <CallButton />
      <button
        aria-label="sidebar toggle"
        className={`hamburger hamburger--collapse pl-4 ml-1 h-full relative ${menuClasses}`}
        type="button"
        onClick={() => {
          handleMenuToggle(!isOpen);
        }}
      >
        <span className="hamburger-box top-0.5">
          <span className="hamburger-inner" />
        </span>
      </button>
      <nav>
        <MobileSideNav
          navigationData={navigationData}
          headerHeight={headerHeight}
          isOpen={isOpen}
        />
      </nav>
    </div>
  );
};

export default HeaderMenuMobile;
