module.exports = {
  redirects: [
    {
      source: '/volunteer/africa/african-wildlife-and-conservation',
      destination:
        '/volunteer-abroad/south-africa/african-wildlife-and-conservation',
      permanent: false,
    },
    {
      source: '/volunteer/africa/game-ranger-course-south-africa',
      destination: '/volunteer-abroad/south-africa/game-ranger',
      permanent: false,
    },
    {
      source: '/volunteer/africa/capetown-community',
      destination: '/volunteer-abroad/south-africa/cape-town-community',
      permanent: false,
    },
    {
      source: '/volunteer/africa/endangered-wildlife-conservation',
      destination:
        '/volunteer-abroad/south-africa/endangered-wildlife-conservation',
      permanent: false,
    },
    {
      source: '/volunteer/africa/cheetah-conservation',
      destination:
        '/volunteer-abroad/south-africa/endangered-wildlife-conservation',
      permanent: false,
    },
    {
      source: '/volunteer/africa/monkey-rehabilitation',
      destination: '/volunteer-abroad/south-africa/monkey-rehabilitation',
      permanent: false,
    },
    {
      source: '/volunteer/africa/tanzania-community',
      destination: '/volunteer-abroad/tanzania/tanzania-community',
      permanent: false,
    },
    {
      source: '/volunteer/africa/healthcare-heroes-zanzibar',
      destination: '/volunteer-abroad/tanzania/healthcare-heroes-zanzibar',
      permanent: false,
    },
    {
      source: '/volunteer/africa/zanzibar-community',
      destination: '/volunteer-abroad/tanzania/zanzibar-community',
      permanent: false,
    },
    {
      source:
        '/volunteer/africa/elephant-conservation-and-helping-local-farmers',
      destination:
        '/volunteer-abroad/namibia/elephant-conservation-and-helping-local-farmers',
      permanent: false,
    },
    {
      source: '/volunteer/europe/marine-conservation-spain',
      destination: '/volunteer-abroad/spain/marine-conservation',
      permanent: false,
    },
    {
      source: '/volunteer/central-america/the-super-hero-project',
      destination: '/volunteer-abroad/costa-rica/the-super-hero-project',
      permanent: false,
    },
    {
      source: '/volunteer/central-america/sea-turtle-conservation',
      destination: '/volunteer-abroad/costa-rica/sea-turtle-conservation',
      permanent: false,
    },
    {
      source: '/volunteer/central-america/sloth-and-wildlife-rescue-sanctuary',
      destination:
        '/volunteer-abroad/costa-rica/sloth-and-wildlife-rescue-sanctuary',
      permanent: false,
    },
    {
      source: '/volunteer/south-america/community-buenos-aires',
      destination: '/volunteer-abroad/argentina/community-buenos-aires',
      permanent: false,
    },
    {
      source: '/volunteer/south-america/the-real-galapagos',
      destination: '/volunteer-abroad/ecuador/the-real-galapagos',
      permanent: false,
    },
    {
      source: '/volunteer/south-america/amazon-shelter',
      destination: '/volunteer-abroad/peru/amazon-shelter',
      permanent: false,
    },
    {
      source: '/volunteer/asia/magical-myanmar',
      destination: '/volunteer-abroad/myanmar/magical-myanmar',
      permanent: false,
    },
    {
      source: '/volunteer/asia/street-dog-rescue-and-rehabilitation',
      destination:
        '/volunteer-abroad/thailand/street-dog-rescue-and-rehabilitation',
      permanent: false,
    },
    {
      source: '/volunteer/asia/humanitarian-volunteer-nepal',
      destination: '/volunteer-abroad/nepal/humanitarian-nepal',
      permanent: false,
    },
    {
      source: '/volunteer/asia/namaste-nepal',
      destination: '/volunteer-abroad/nepal/namaste-nepal',
      permanent: false,
    },
    {
      source: '/volunteer/asia/humanitarian-volunteer-nepal',
      destination: '/volunteer-abroad/nepal/humanitarian-nepal',
      permanent: false,
    },
    {
      source: '/volunteer/asia/trek-and-teach-nepal',
      destination: '/volunteer-abroad/nepal/trek-&-teach',
      permanent: false,
    },
    {
      source: '/volunteer/asia/wildlife-rescue-indonesia',
      destination: '/volunteer-abroad/nepal/wildlife-rescue-indonesia',
      permanent: false,
    },
    {
      source: '/volunteer/asia/elephant-rehabilitation-and-rescue',
      destination:
        '/volunteer-abroad/thailand/elephant-rescue-and-rehabilitation',
      permanent: false,
    },
    {
      source: '/volunteer/asia/trunks-and-tails-rescue',
      destination: '/volunteer-abroad/thailand/trunks-and-tails-rescue',
      permanent: false,
    },
    {
      source: '/volunteer/filter/nature',
      destination: '/volunteer-abroad',
      permanent: false,
    },
    {
      source: '/volunteer/filter/communities',
      destination: '/volunteer-abroad',
      permanent: false,
    },
    {
      source: '/volunteer/filter/wildlife',
      destination: '/volunteer-abroad',
      permanent: false,
    },
    {
      source: '/volunteer/europe',
      destination: '/volunteer-abroad',
      permanent: false,
    },
    {
      source: '/volunteer/central-america',
      destination: '/volunteer-abroad',
      permanent: false,
    },
    {
      source: '/volunteer/south-america',
      destination: '/volunteer-abroad',
      permanent: false,
    },
    {
      source: '/volunteer/asia',
      destination: '/volunteer-abroad',
      permanent: false,
    },
    {
      source: '/volunteer/africa',
      destination: '/volunteer-abroad',
      permanent: false,
    },
    {
      source: '/volunteer/:trip*',
      destination: '/volunteer-abroad/:trip*',
      permanent: false,
    },
    {
      source: '/volunteer',
      destination: '/volunteer-abroad',
      permanent: false,
    },
    {
      source: '/au-pair/australia',
      destination: '/au-pair-abroad/australia',
      permanent: false,
    },
    {
      source: '/au-pair/canada',
      destination: '/au-pair-abroad/canada',
      permanent: false,
    },
    {
      source: '/au-pair/new-zealand',
      destination: '/au-pair-abroad/new-zealand',
      permanent: false,
    },
    {
      source: '/au-pair/uk',
      destination: '/au-pair-abroad/uk',
      permanent: false,
    },
    {
      source: '/au-pair/usa',
      destination: '/au-pair-abroad/usa',
      permanent: false,
    },
    {
      source: '/au-pair/china',
      destination: '/au-pair-abroad/china',
      permanent: false,
    },
    {
      source: '/au-pair/europe',
      destination: '/au-pair-abroad',
      permanent: false,
    },
    {
      source: '/au-pair',
      destination: '/au-pair-abroad',
      permanent: false,
    },
    {
      source: '/au-pair/:trip*',
      destination: '/au-pair-abroad/:trip*',
      permanent: false,
    },
    {
      source: '/teach/thailand/hua-hin',
      destination: '/teach-abroad/thailand/teach-in-thailand-hua-hin',
      permanent: false,
    },
    {
      source: '/teach/thailand/chiang-mai',
      destination: '/teach-abroad/thailand/teach-in-thailand-chiang-mai',
      permanent: false,
    },
    {
      source: '/teach/thailand/teach-camps',
      destination: '/teach-abroad/thailand/teach-camps-in-thailand',
      permanent: false,
    },
    {
      source: '/teach/thailand/teach-resort',
      destination: '/teach-abroad/thailand/teach-resort-hua-hin',
      permanent: false,
    },
    {
      source: '/teach/thailand/summer-teaching',
      destination: '/teach-abroad/thailand/summer-teach-in-thailand-hua-hin',
      permanent: false,
    },
    {
      source: '/teach-abroad/south-korea/teach-in-south-korea-seoul',
      destination: '/teach-abroad/south-korea/teach-in-south-korea-incheon',
      permanent: false,
    },
    {
      source: '/teach-abroad/italy/teach-in-italy-florence',
      destination: '/teach-abroad/italy/teach-in-italy-tuscany',
      permanent: false,
    },
    {
      source: '/teach/thailand',
      destination: '/teach-abroad',
      permanent: false,
    },
    {
      source: '/teach/peru',
      destination: '/teach-abroad/peru/teach-in-peru-arequipa',
      permanent: false,
    },
    {
      source: '/teach/south-korea',
      destination:
        '/teach-abroad/south-korea/teach-in-south-korea-online-tesol',
      permanent: false,
    },
    {
      source: '/teach/vietnam',
      destination: '/teach-abroad/vietnam/teach-in-vietnam-ho-chi-minh-city',
      permanent: false,
    },
    {
      source: '/teach/myanmar',
      destination: '/teach-abroad/myanmar/teach-in-myanmar-yangon',
      permanent: false,
    },
    {
      source: '/teach/china',
      destination: '/teach-abroad/china/teach-in-china-beijing-online-tesol',
      permanent: false,
    },
    {
      source: '/teach/costa-rica',
      destination: '/teach-abroad/costa-rica/teach-in-costa-rica-playa-samara',
      permanent: false,
    },
    {
      source: '/teach',
      destination: '/teach-abroad',
      permanent: false,
    },
    {
      source: '/teach/:trip*',
      destination: '/teach-abroad/:trip*',
      permanent: false,
    },
    {
      source: '/internship/australia',
      destination: '/internship-abroad/australia',
      permanent: false,
    },
    {
      source: '/internship/canada',
      destination: '/internship-abroad/canada',
      permanent: false,
    },
    {
      source: '/internship/italy',
      destination: '/internship-abroad/italy',
      permanent: false,
    },
    {
      source: '/internship/new-zealand',
      destination: '/internship-abroad/new-zealand',
      permanent: false,
    },
    {
      source: '/internship/spain',
      destination: '/internship-abroad/spain',
      permanent: false,
    },
    {
      source: '/internship/singapore',
      destination: '/internship-abroad/singapore',
      permanent: false,
    },
    {
      source: '/internship/malaysia',
      destination: '/internship-abroad/malaysia',
      permanent: false,
    },
    {
      source: '/internship/thailand',
      destination: '/internship-abroad/thailand',
      permanent: false,
    },
    {
      source: '/internship/hong-kong',
      destination: '/internship-abroad/hong-kong',
      permanent: false,
    },
    {
      source: '/internship',
      destination: '/internship-abroad',
      permanent: false,
    },
    {
      source: '/internship/:trip*',
      destination: '/internship-abroad/:trip*',
      permanent: false,
    },
    {
      source: '/tutor-abroad/italy/tutor-in-france',
      destination: '/tutor-abroad/france/tutor-in-france',
      permanent: false,
    },

    { source: '/our-story', destination: '/about-us', permanent: false },
    { source: '/global-foundation', destination: '/', permanent: false },
    { source: '/news', destination: '/press', permanent: false },
    {
      source: '/become-a-partner',
      destination: '/partners',
      permanent: false,
    },
    { source: '/join-our-team', destination: '/careers', permanent: false },
    {
      source: '/staffing',
      destination: 'https://try.globalworkandtravel.com/staffing',
      permanent: false,
    },
    {
      source: '/share-the-fun',
      destination: 'https://gworld.globalworkandtravel.com/',
      permanent: false,
    },
    {
      source: '/refer-a-friend',
      destination: 'https://gworld.globalworkandtravel.com/',
      permanent: false,
    },
    { source: '/global-radio', destination: '/', permanent: false },
    {
      source: '/book',
      destination: 'http://book.globalworkandtravel.com',
      permanent: false,
    },
    {
      source: '/resume',
      destination: 'https://gworld.globalworkandtravel.com',
      permanent: false,
    },
    {
      source: '/travel',
      destination: 'https://gworld.globalworkandtravel.com',
      permanent: false,
    },
    {
      source: '/travelsim',
      destination:
        'https://www.travelsim.net.au/OnlineReferral/globalworkandtravel/shop/',
      permanent: false,
    },
    { source: '/mediacentre', destination: '/press', permanent: false },
    { source: '/foundation', destination: '/', permanent: false },
    { source: '/globalfoundation', destination: '/', permanent: false },
    {
      source: '/refer-friend',
      destination: 'https://gworld.globalworkandtravel.com/',
      permanent: false,
    },
    {
      source: '/privacy-policy',
      destination: '/terms-and-conditions?tab=pp',
      permanent: false,
    },
  ],
};
