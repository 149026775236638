import Link from 'next/link';

const Privacy = () => {
  return (
    <div className=" text-dark-700 text-[0.688rem] text-center font-normal !leading-4">
      {`We keep your data cozy in our digital fortress—proceed and you're cool
      with our`}{' '}
      <Link href="/terms-and-conditions?tab=pp">
        <a className="text-teal-800 cursor-pointer underline">{`Privacy Policy.`}</a>
      </Link>{' '}
      For a swift escape, just opt-out anytime.
    </div>
  );
};

export { Privacy };
