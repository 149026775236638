import '../styles/globals.css';
import type { AppProps } from 'next/app';
import React, { useRef } from 'react';
import MainLayout from '../layouts/main.layout';
import { ApolloProvider } from '@apollo/client';
import { CookiesProvider } from 'react-cookie';
import { useRouter } from 'next/router';
import { Meta } from '@/Components/meta/meta';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { StructuredData } from '@/Components/structured-data/structured-data';
import { ToastContainer } from 'react-toastify';
import { useSetupIntercom } from '@/Lib/hooks/useSetupIntercom';
import { useRudderstackPage } from '@/Lib/hooks/rudderstack/useRudderstackPage';
import { GlobalStateProvider } from '@/State/global/global.provider';
import { Head } from '@/Components/head/head';
import { UtmParamsStorageService } from '@/Lib/helpers/utm-params-storage.service';
import queryString from 'query-string';
import { useApollo } from '@/Lib/graphql/apollo-client';
import {AppPropsWithLayout} from "@/Lib/types/app";

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  const apolloClient = useApollo();
  const router = useRouter();
  const hasParamsSaved = useRef(false);
  const params = useRouter().asPath.split(/\?/)[1];
  const query = queryString.parse(params);

  useSetupIntercom();
  useRudderstackPage();

  const Layout = Component.Layout || MainLayout

  if (
    typeof window !== 'undefined' &&
    !hasParamsSaved.current &&
    Object.keys(query).length
  ) {
    UtmParamsStorageService.setItem(query);
    hasParamsSaved.current = true;
  }

  return (
    <ApolloProvider client={apolloClient}>
      <GlobalStateProvider>
        <CookiesProvider>
          <Meta data={pageProps.meta} />
          <Head />
          <StructuredData />
          <Layout pageProps={pageProps}>
            <SwitchTransition mode="out-in">
              <CSSTransition
                key={router.asPath}
                timeout={300}
                classNames="page-transition"
              >
                <>
                  <Component {...pageProps} />
                  <ToastContainer />
                </>
              </CSSTransition>
            </SwitchTransition>
          </Layout>
        </CookiesProvider>
      </GlobalStateProvider>
    </ApolloProvider>
  );
}
export default MyApp;
