import { Input } from '@/Components/form-elements/Input/Input';
import { TFields, Tabs } from '../login';
import { SocialButtons } from '../form/socialButtons';
import { Privacy } from '../form/privacy';
import { LoginTemplateData } from '@/Lib/types/login';
import { ButtonAuth } from '../form/button-auth';

type Props = {
  loading: boolean;
  loginWith: any;
  checkEmail: any;
  changeHandler: any;
  errors: TFields;
  fields: TFields;
  tabData: LoginTemplateData | null;
  type: string;
};

const InitTab = ({
  loading,
  loginWith,
  checkEmail,
  changeHandler,
  errors,
  fields,
  tabData,
  type,
}: Props) => {

  function handleKeyPress(event: any) {
    if (event.key === 'Enter') {
      checkEmail(event);
    }
  }

  return (
    <div>
      <div className="px-4 pb-4 md:px-6 md:pb-6">
        <div className={'flex flex-col mt-4 md:mt-6 '}>
          <label className="text-sm font-semibold text-dark-700 text-left !leading-4">
            Email address
          </label>
          <Input
            name="email"
            error={errors.email}
            value={fields.email}
            changeHandler={changeHandler}
            errorBorder={true}
            fontColor="text-dark-700"
            classes=" mt-1.5 !rounded-md"
            errorClass=" !text-red-900 !text-2xs font-semibold"
            type="email"
            onKeyPress={handleKeyPress}
          />
        </div>

        <div className="mt-4 md:mt-6 w-full ">
          <ButtonAuth
            tab={Tabs.init}
            tabData={tabData}
            onClick={checkEmail}
            loading={loading}
            type={type}
          />
        </div>
      </div>

      <div className="flex items-center">
        <hr className=" border-t border-light-800 flex-grow" />
        <div className="px-2 text-xs text-dark-600 font-semibold">OR</div>
        <hr className=" border-t border-light-800 flex-grow" />
      </div>
      <div className="flex justify-between items-center my-4 md:my-6 px-4 md:px-6">
        <SocialButtons brand="apple" loginWith={loginWith} />
        <SocialButtons brand="google" loginWith={loginWith} />
        <SocialButtons brand="facebook" loginWith={loginWith} />
      </div>

      <div className="bg-light-700 px-4 md:px-6 py-2 md:py-4 border-t border-light-800 flex justify-center items-center text-base !leading-4  text-dark-700 font-semibold">
        <Privacy />
      </div>
    </div>
  );
};
export { InitTab };
