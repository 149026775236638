import * as io from 'socket.io-client';
import { useEffect, useRef, useState } from 'react';
import { SessionStorageService } from '@/Lib/services/session-storage';

const IO_URL = 'https://socialproofs.globalworkandtravel.com';
const SHOW_TIME = 15000;
const WAIT_TIME = 30000;
const INITIAL_TIME = 15000;

const notificationSession = new SessionStorageService('notificationID');

const useNotification = () => {
  const [isShown, setIsShown] = useState<boolean>(false);
  const [data, setData] = useState<any>(null);
  const userChoseToCloseRef = useRef(false);

  const neverShowAgain = () => {
    setIsShown(false);
    userChoseToCloseRef.current = true;
  };

  useEffect(() => {
    // prevent running on server
    if (typeof window === 'undefined') return;

    const key = notificationSession.get();
    const socket = io(IO_URL, {
      query: {
        id: key,
      },
    });

    // Store the newly created id if the one provided is invalid
    socket.on('id', (id: any) => {
      notificationSession.set(id);
    });

    // On notification received create notification
    socket.on('notification', (data: any) => {
      if (data?.first_name.toLowerCase() !== 'test') {
        setData(data);
        setIsShown(true);
        setTimeout(() => {
          setIsShown(false);
        }, SHOW_TIME);
      } else {
        socket.emit('get-notification');
      }
    });

    // disclaimer: user must see the social-proof after INTIAL_TIME for the
    // first time, but then, we'll wait longer to show him/her. That's the
    // reason for the mess below
    let interval: NodeJS.Timer;

    setTimeout(() => {
      socket.emit('get-notification');
      interval = setInterval(() => {
        if (!userChoseToCloseRef.current) {
          socket.emit('get-notification');
        }
      }, WAIT_TIME + SHOW_TIME);
    }, INITIAL_TIME);

    return () => clearInterval(interval);
  }, []);

  return { isShown, data, neverShowAgain };
};

export { useNotification };
