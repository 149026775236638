import { LoginTemplateData } from '@/Lib/types/login';
import { Tabs } from '../login';

type Props = {
  tab: Tabs;
  tabData: LoginTemplateData | null;
  type: string;
};

const defaultTitle = (tab: Tabs) => {
  switch (tab) {
    case Tabs.init:
      return `We've got a spot for you, too!`;
      break;

    case Tabs.login:
      return 'Login';
      break;

    case Tabs.register:
      return `We've got a spot for you, too!`;
      break;

    case Tabs.forgetPass:
      return 'Forget password';
      break;

    case Tabs.loginSocial:
      return 'Login';
      break;

    default:
      break;
  }
};

const FormTitle = ({ tab, tabData, type }: Props) => {
  const returnTabData = (tab: string) => {
    const data = tabData?.loginTemplateCollection.items.filter(
      (item) => item.type === (tab === Tabs.init ? type : tab)
    );
    if (data) {
      return data[0];
    } else {
      return null;
    }
  };

  return (
    <div className="text-dark-800 font-bold text-lg md:text-2xl w-full items-center text-center !leading-6 ">
      {returnTabData(tab)?.title || defaultTitle(tab)}
    </div>
  );
};
export { FormTitle };
