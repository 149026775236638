import React, { useEffect, useContext, useState, useRef } from 'react';

import classes from './mobile-side-nav.module.css';
import HeaderWhyUsMenu from '@/Components/header/header-why-us-menu/header-why-us-menu';
import HeaderAboutMenu from '@/Components/header/header-about-menu/header-about-menu';
import HeaderDivisionsMenu from '@/Components/header/header-divisions-menu/header-divisions-menu';
import HeaderDestinationsMenu from '@/Components/header/header-destinations-menu/header-destinations-menu';
import SearchInput from '@/Components/header/search-input/search-input';
import { useRouter } from 'next/router';
import Link from 'next/link';
import getMenus from '../header/header-destinations-menu/helpers';
import { BookACallContext, GetInTouchToggleContext } from 'layouts/main.layout';
import { useAtomValue } from 'jotai';
import { promotionsAtom } from '@/State/global/global.store';

function MobileSideNav({
  isOpen,
  headerHeight,
  navigationData,
}: {
  isOpen: boolean;
  headerHeight: number;
  navigationData: any;
}) {
  const router = useRouter();
  const { height: bannerHeight } = useAtomValue(promotionsAtom);
  const topAnchorRef = useRef<HTMLDivElement>(null);
  const getInTouchContext = useContext(GetInTouchToggleContext);
  const bookACallContext = useContext(BookACallContext);

  const openGetInTouch = () => {
    getInTouchContext?.setIsGetInTouchOpen(true);
    // must make sure no previous tripId which could be set by
    // ask-a-question component is sent so iframe with correct url is shown
    bookACallContext?.setTripId(0);
  };

  const [subMenu, setSubMenu] = useState<{
    title: string;
    component: JSX.Element;
  } | null>();

  useEffect(() => {
    router.events.on('routeChangeStart', () => {
      setSubMenu(null);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [subMenuState, setSubMenuState] = useState(false);

  const sidenavClasses = `${classes.sideNav} ${isOpen ? classes.active : ''}`;
  const sidenavStyles = {
    height: `calc(100vh - ${headerHeight}px - ${bannerHeight}px)`,
    top: `calc(${headerHeight}px + ${bannerHeight}px)`,
  };

  useEffect(() => {
    if (!isOpen) {
      topAnchorRef.current?.scrollIntoView();
    } else {
      setSubMenuState(false);
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }

    return () => {
      // make sure overflow-hidden is removed from body once dismounted
      // this usually happens when user clicks on browser back button instead
      // of closing the dialog like a normal human being!!! Just kidding though!
      document.body.classList.remove('overflow-hidden');
    };
  }, [isOpen]);

  // const navigationData = useQuery(NAVIGATION_MENU_QUERY);
  const [regionsMenuData, setRegionsMenuData] = useState<any>();

  useEffect(() => {
    if (
      !navigationData.loading &&
      navigationData.data &&
      navigationData.data.regions
    ) {
      const result = [...navigationData.data.regions].map((r: any) => {
        let resgoin = { ...r };
        resgoin.destinations = resgoin.destinations.filter(
          (d: any) => d.count > 0
        );
        return resgoin;
      });
      setRegionsMenuData(getMenus(result));
    }
  }, [navigationData.loading, navigationData.data]);

  const subMenuNavs = [
    {
      title: 'What',
      component: (
        <HeaderDivisionsMenu
          navigationData={navigationData}
          activeMenu="What"
        />
      ),
    },
    {
      title: 'Where',
      component: (
        <HeaderDestinationsMenu
          navigationData={regionsMenuData}
          activeMenu="Where"
        />
      ),
    },
    {
      title: 'Why',
      component: <HeaderWhyUsMenu activeMenu="Why" />,
    },
    {
      title: 'How',
      component: <HeaderAboutMenu activeMenu="How" />,
    },
  ];

  const handleSubmenuChange = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    submenu: { title: string; component: JSX.Element }
  ) => {
    e.stopPropagation();
    setSubMenu(submenu);
    setSubMenuState(true);
  };

  const handleSubMenuClose = () => {
    setSubMenuState(false);
  };

  return (
    <div
      tabIndex={0}
      className={`fixed text-left px-7 pt-4 right-0 left-0 top-0 bottom-0 h-fit bg-white z-30 overflow-y-scroll overflow-x-hidden outline-none ${sidenavClasses}`}
      style={sidenavStyles}
      onClick={(e) => e.stopPropagation()}
    >
      <div className="sn-top-anchor absolute top-0" ref={topAnchorRef} />
      <SearchInput classes="border-b border-solid border-grey px-2" />

      <ul className="py-4">
        {subMenuNavs.map((s) => (
          <li key={s.title}>
            <div
              className="flex justify-between py-4 text-dark-900 "
              onClick={(e) => handleSubmenuChange(e, s)}
            >
              <h3 className="font-semibold relative">{s.title}</h3>
              <i className="icon-right" />
            </div>
          </li>
        ))}
      </ul>
      <hr />
      <div className="py-4">
        <ul>
          <li className="py-2 text-darkgrey">
            <h3>
              <Link href="/contact">
                <a>Contact us</a>
              </Link>
            </h3>
          </li>
          <li className="py-2 text-darkgrey">
            <h3>
              <Link href="/terms-and-conditions?tab=bc">
                <a>Booking conditions</a>
              </Link>
            </h3>
          </li>
          <li className="py-2 text-darkgrey">
            <h3>
              <Link href="/terms-and-conditions?tab=tc">
                <a>Website terms of use</a>
              </Link>
            </h3>
          </li>
          <li className="py-2 text-darkgrey">
            <h3>
              <Link href="/terms-and-conditions?tab=pp">
                <a>Privacy policy</a>
              </Link>
            </h3>
          </li>
        </ul>
      </div>
      <div
        className={`sn-sub-menu absolute top-0 left-0 w-full h-full bg-white z-40 pt-4 overflow-y-scroll  ${
          classes.sideNav
        } ${subMenuState ? classes.active : ''}`}
      >
        <button
          aria-label="close sub-menu"
          className="flex flex-row items-center px-2"
          onClick={handleSubMenuClose}
        >
          <i className="icon-left mr-2" />
          <h3 className="font-semibold h-full">{subMenu && subMenu.title}</h3>
        </button>
        <div className="px-6 pb-32 bg-white">
          {subMenu && subMenu.component}
        </div>
      </div>
    </div>
  );
}

export default MobileSideNav;
