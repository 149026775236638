function getMenus(regions: any) {
  const [americaMenu, otherMenus] = mergeAmericas(regions);

  // Sort america list alphabetically
  americaMenu.submenu = americaMenu.submenu.sort((a: any, b: any) =>
    a.name.localeCompare(b.name)
  );
  return [americaMenu, ...otherMenus];
}

function mergeAmericaRegions(regions: any) {
  const [americas, others] = regions.reduce(
    (arrays: any[], region: any) => {
      const [americas, others] = arrays;

      if (region?.name?.toLowerCase().indexOf('america') >= 0) {
        americas.destinations = [
          ...americas.destinations,
          ...region.destinations,
        ];
      } else {
        others.push({
          title: region.name,
          destinations: region.destinations,
        });
      }

      return [americas, others];
    },
    [
      {
        title: 'Americas',
        destinations: [],
      },
      [],
    ]
  );

  americas.destinations = americas.destinations.sort((a: any, b: any) =>
    a.name.localeCompare(b.name)
  );
  return [americas, ...others];
}
type Name = {
  name: string;
  internalName: string;
};
function mergeAmericas(regions: any) {
  return regions?.reduce(
    (arrays: any, region: any) => {
      const [americas, others] = arrays;

      const destinations = region.destinations?.map((destination: Name) => ({
        name: destination.name,
        link: `/destination/${destination.internalName}/`,
      }));

      if (region.name?.toLowerCase().indexOf('america') >= 0) {
        americas.submenu = [...americas.submenu, ...destinations];
      } else {
        others.push({
          title: region.name,
          submenu: destinations,
        });
      }

      return [americas, others];
    },
    [
      {
        title: 'Americas',
        submenu: [],
      },
      [],
    ]
  );
}
export default getMenus;
