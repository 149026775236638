import React, {FC, useRef, useState} from 'react';
import algoliasearch, {SearchIndex} from 'algoliasearch';
import { useRouter } from 'next/router';
import AsyncSelect from 'react-select/async';
import lodash from 'lodash';
import Select from "react-select/base";

type Props = {
  classes?: string;
};

const SearchInput: FC<Props> = ({ classes }) => {
  const router = useRouter();
  const [inputValue, setInputValue] = useState<string | null>(null);
  const algoliaAppId = process?.env?.NEXT_PUBLIC_ALGOLIA_APP_ID;
  const algoliaKey = process?.env?.NEXT_PUBLIC_ALOLIA_KEY;
  const [hasFocused, setHasFocused] = useState<boolean>(false);
  const selectInputRef = useRef<Select>(null);

  const client = algoliasearch(
    algoliaAppId ? algoliaAppId : '',
    algoliaKey ? algoliaKey : ''
  );``

  const index = client.initIndex(
    `${process?.env?.NEXT_PUBLIC_ENVIROMENT}_website_search`
  );

  const OnClickSearch = (url: string) => {
    router.push(url);
    setInputValue(null);
  };

  const promiseOptions = (inputValue: string, callback: any) => {

    index
      .search(inputValue)
      .then((response) => {
        const result = response.hits.map((hit: any) => ({
          value: hit.url,
          label: hit.tripName || hit.title,
        }));

        callback(result);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const debouncedLoadOptions = lodash.debounce(promiseOptions, 500);

  const handleInputFocus = () => {
    if(!hasFocused) {
      setHasFocused(true)
      setTimeout(() => {
        selectInputRef.current?.focus()
      }, 250)
    }
  };

  return (
    <div className={`${classes}`}>
      <div className="flex justify-center items-center w-full relative">
        <i className="icon-search mr-3 absolute left-0 top-0 h-full flex items-center pl-0 lg:pl-1 pointer-events-none" />
        <AsyncSelect
          id="search-input-header-dropdown"
          ref={selectInputRef}
          key={hasFocused ? 'focused' : 'not-focused'}
          instanceId="search-input-header-dropdown"
          value={inputValue}
          closeMenuOnSelect={true}
          classNamePrefix="auto-search"
          noOptionsMessage={() => null}
          cacheOptions
          onFocus={handleInputFocus}
          styles={{
            container: (styles) => ({
              ...styles,
              width: '100%',
            }),
            control: (styles) => ({
              ...styles,
              border: '0px',
            }),
            dropdownIndicator: (styles) => ({
              ...styles,
              display: 'none',
              padding: 0,
            }),
            clearIndicator: (styles) => ({
              ...styles,
              display: 'none',
              padding: 0,
            }),
            indicatorSeparator: (styles) => ({
              ...styles,
              display: 'none',
              padding: 0,
            }),
            menu: (styles) => ({
              ...styles,
              left: '-1px',
              border: '0px',
              zIndex: 9999,
              boxShadow:
                '0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%)',
              top: '2rem',
            }),
            input: (styles) => ({
              ...styles,
              border: 'none',
            }),
            option: (styles) => ({
              ...styles,
              lineHeight: '48px',
              height: '48px',
              padding: '0 16px',
              textAlign: 'left',
              textDecoration: 'none',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              cursor: 'pointer',
            }),
          }}
          onChange={(e: any) => e && OnClickSearch(e?.value)}
          placeholder="Explore trips"
          loadOptions={debouncedLoadOptions}
          defaultOptions={hasFocused}
          openMenuOnFocus={true}
          className="flex-grow pl-6  caret-teal-800 border-0 outline-none placeholder-dark-600 font-normal cursor-pointer"
          theme={(theme) => ({
            ...theme,
            borderRadius: 0,
            colors: {
              ...theme.colors,
              primary25: '#f5f5f5',
              primary: '#fff',
            },
          })}
        />
      </div>
    </div>
  );
};

export default SearchInput;
