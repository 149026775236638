import Head from 'next/head';
import { FC } from 'react';
import { TStructuredData } from './type';

type props = {
  trip: TStructuredData;
};

const ProductPageStructuredData: FC<props> = ({ trip }) => {
  const data = {
    '@context': 'https://schema.org/',
    '@type': 'Product',
    name: trip.name,
    image:
      'https://res.cloudinary.com/gwatco/image/upload/t_product-banner-md,dpr_auto/' +
      trip.image,
    // url: trip.url,
    description: trip.overview.split('</p>')[0].replace(/<[^>]*>/g, ''),
    sku: trip.id,
    mpn: trip.id,
    brand: {
      '@type': 'Brand',
      name: 'Global Work & Travel',
    },
    ...(trip.externalReviews.total > 0 && trip.reviews?.rating
      ? {
          review: {
            '@type': 'Review',
            reviewRating: {
              '@type': 'Rating',
              ratingValue: trip.externalReviews.data[0].rating,
              bestRating: '5',
            },
            author: {
              '@type': 'Person',
              name: trip.externalReviews.data[0].contact.data.name,
            },
          },
          aggregateRating: {
            '@type': 'AggregateRating',
            ratingValue: trip.reviews.rating,
            reviewCount: trip.reviews.count,
          },
        }
      : ''),
    offers: {
      '@type': 'AggregateOffer',
      offerCount: '2',
      lowPrice: trip.deposit,
      highPrice: trip.price,
      priceCurrency: trip.currency,
    },
  };
  return (
    <Head>
      <script type="application/ld+json">{JSON.stringify(data)}</script>
    </Head>
  );
};

export { ProductPageStructuredData };
