export function getEmailValidation(email: string): boolean {
  // Reference: https://stackoverflow.com/a/201378
  const emailRegex =
    /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
  return emailRegex.test(email);
}

export function getStrongPasswordValidation(password: string): boolean {
  const regex = /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})/;
  return regex.test(password);
}

export function getSpecialCharValidation(password: string): boolean {
  const regex = /(?=.*[^A-Za-z0-9])/;
  return regex.test(password);
}

export function getLowCharCharValidation(password: string): boolean {
  const regex = /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/;
  return regex.test(password);
}

export function getLengthPassCharValidation(password: string): boolean {
  const regex = /(?=.{6,})/;
  return regex.test(password);
}

export function getNameValidation(text: string): boolean {
  const regex = /^(?:(?![\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF\uFB50-\uFDCF\uFDF0-\uFDFF\uFE70-\uFEFF])[^\p{C}\p{S}\p{P}\p{Mn}\d]|[a-zA-ZÀ-ÿĀ-ž\s'-.,])+$/u;

  return regex.test(text);
}

export function getNumberValidation(text: string): boolean {
  const regex = /^[0-9]*$/;
  return regex.test(text);
}
