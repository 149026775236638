import {
  TBndlSelected,
  TDateSelected,
  TDurationChanged,
  TExplorebyInterestClicked,
  TFindMyTripClicked,
  THomePageViewed,
  TLandingPageViewed,
  TProductAdded,
  TProductAddedtoWishlist,
  TProductFAQClicked,
  TProductListViewClicked,
  TProductListViewed,
  TProductRemovedFromWishlist,
  TProductViewed,
  TPromotionClicked,
  TPromotionViewed,
  TQualificationStarted,
  TQualificationTypeState,
  TRecentDiscoveriesClicked,
  TSubscribe,
  TTrendingTripsClicked,
} from '@/DataTypes/track-events';

declare const rudderanalytics: any;

export class RudderAnalytics {
  private static track(eventName: string, data: any = {}) {
    console.debug(eventName, data);
    rudderanalytics.track(eventName, data);
  }

  static homePageViewed(data: THomePageViewed) {
    this.track('Home Page Viewed', data);
  }

  static productFaqClicked(data: TProductFAQClicked) {
    this.track('Product FAQ Clicked', data);
  }

  static findMyTripClicked(data: TFindMyTripClicked) {
    this.track('Find my trip Clicked', data);
  }

  static recentDiscoveriesClicked(data: TRecentDiscoveriesClicked) {
    this.track('Recent Discoveries Clicked', data);
  }

  static trendingTripsClicked(data: TTrendingTripsClicked) {
    this.track('Trending Trips Clicked', data);
  }

  static productListViewedClicked(data: TProductListViewClicked) {
    this.track('Product List View Clicked', data);
  }

  static exploreByIntrestClicked(data: TExplorebyInterestClicked) {
    this.track('Explore by Interest Clicked', data);
  }

  static landingPageViewed(data: TLandingPageViewed) {
    this.track('Landing Page Viewed', data);
  }

  static productListViewed(data: TProductListViewed) {
    this.track('Product List Viewed', data);
  }

  static promotionViewed(data: TPromotionViewed) {
    this.track('Promotion Viewed', data);
  }

  static promotionClicked(data: TPromotionClicked) {
    this.track('Promotion Clicked', data);
  }

  static productViewed(data: TProductViewed) {
    this.track('Product Viewed', data);
  }

  static productAdded(data: TProductAdded) {
    this.track('Product Added', data);
  }

  static qualificationStarted(data: TQualificationStarted) {
    this.track('Qualification Started', data);
  }

  static qualificationTypeQualified(
    type: string,
    data: TQualificationTypeState
  ) {
    this.track(`Qualification - ${type} Qualified`, data);
  }

  static qualificationTypeUnqualified(
    type: string,
    data: TQualificationTypeState
  ) {
    this.track(`Qualification - ${type} Unqualified`, data);
  }

  static productAddedToWishlist(data: TProductAddedtoWishlist) {
    this.track('Product Added to Wishlist', data);
  }

  static productRemovedFromWishlist(data: TProductRemovedFromWishlist) {
    this.track('Product Removed from Wishlist', data);
  }

  static durationChanged(data: TDurationChanged) {
    this.track('Duration Changed', data);
  }

  static bndlSelected(data: TBndlSelected) {
    this.track('BNDL Selected', data);
  }

  static dateSelected(data: TDateSelected) {
    this.track('Date Selected', data);
  }
  static newsletterSubscribed(data: TSubscribe) {
    this.track('Newsletter Subscribed', data);
  }
}
