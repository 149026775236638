import {LocalStorageService} from "@/Lib/helpers/local-storage.service";
import { ParsedQuery } from "query-string";

class UtmParamsStorage extends LocalStorageService<ParsedQuery> {
  setItem(value: ParsedQuery): void {
    delete value.promo;
    super.setItem(value);
  }

  getFormatted(): { [p: string]: string } {
    const data = super.getItem();

    return data ? JSON.parse(data) : null;
  }

  toQueryParams(): string | null {
    const data = this.getFormatted();
    if (!data) return null;

    const encodedParams = Object.keys(data)
      .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
      .join('&');

    return encodedParams;
  }
}

export const UtmParamsStorageService = new UtmParamsStorage('utm-params');