import { BasicDivsion } from '@/Lib/types/division';
import { FC } from 'react';
import Card from '@/Components/header/header-divisions-menu/division-card';

function sortStudyLast(divisions: BasicDivsion[]) {
  const result = divisions
    ?.slice()
    .sort((a: BasicDivsion, b: BasicDivsion) =>
      a.internalName === 'study-abroad'
        ? 1
        : b.internalName === 'study-abroad'
        ? -1
        : 0
    );
  //Swap Study & Tour
  return result
    ?.slice()
    .sort((a: BasicDivsion, b: BasicDivsion) =>
      a.internalName === 'tour' ? 1 : b.internalName === 'tour' ? -1 : 0
    );
}

type Props = {
  navigationData: any;
  activeMenu: string | null;
};

const HeaderDivisionsMenu: FC<Props> = ({ navigationData, activeMenu }) => {
  const isShown = activeMenu && activeMenu === 'What';

  return (
    <ul
      className={
        'nav-menu-fade-in duration-500 ease-in-out transition-opacity max-w-6.5xl mx-auto grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 md:max-w-2xl lg:max-w-4xl xl:max-w-6.5xl gap-6 pt-10 pb-0 md:pb-10 font-normal' +
        (isShown ? ' block' : ' hidden')
      }
    >
      {!navigationData.loading && navigationData.data
        ? sortStudyLast(navigationData.data.divisions).map(
            (d: BasicDivsion, index: number) => (
              <li key={index} className="text-dark-900">
                <Card division={d} />
              </li>
            )
          )
        : null}
      {/* <li className="text-dark-900">
        <Card division={toursDivisions} isCominSoon={true} />
      </li> */}
    </ul>
  );
};

export default HeaderDivisionsMenu;
