import React from 'react';

type InputProps = {
  labelText?: string;
  error?: string;
  doesntHaveError?: boolean;
  doesntHaveBorder?: boolean;
  hasCenteredText?: boolean;
  hasFullWidth?: boolean;
  value: string;
  name: string;
  placeholder?: string;
  changeHandler: (e: React.FormEvent<HTMLInputElement>) => void;
  type?: string;
  fontColor?: string;
  classes?: string;
  errorClass?: string;
  errorBorder?: boolean;
  disabled?: boolean;
  onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
};

function Input({
  labelText,
  error,
  name,
  value,
  doesntHaveError = false,
  doesntHaveBorder = false,
  hasCenteredText = false,
  hasFullWidth = false,
  placeholder,
  changeHandler,
  type = 'text',
  fontColor = 'text-dark-900',
  classes,
  errorClass = '',
  errorBorder = false,
  disabled = false,
  onKeyPress,
}: InputProps): JSX.Element {
  return (
    <div
      className={
        'flex-grow flex flex-col' +
        (labelText ? ' mt-4' : '') +
        (hasFullWidth ? ' w-full' : '')
      }
    >
      {labelText ? (
        <label className="text-dark-800 font-semibold m-0.5 leading-4.5 uppercase text-sm">
          {labelText}
        </label>
      ) : null}
      <input
        type={type}
        className={
          `leading-5 px-2.5 rounded-sm outline-none caret-teal-800 focus:outline-none text-sm ${fontColor} ` +
          (errorBorder && error && ' !border-red-900') +
          (labelText ? ' py-3' : ' py-3.5') +
          (doesntHaveBorder ? '' : ' border border-light-900') +
          (hasCenteredText ? ' text-center' : '') +
          (hasFullWidth ? ' w-full' : '') +
          ` ${classes}`
        }
        placeholder={placeholder || ''}
        name={name}
        value={value}
        onChange={changeHandler}
        disabled={disabled}
        onKeyPress={onKeyPress}
      />
      {doesntHaveError
        ? null
        : error && (
            <div className={'mt-1 text-red-900 ' + errorClass}>{error}</div>
          )}
    </div>
  );
}

export { Input };
