import { Input } from '@/Components/form-elements/Input/Input';
import { TFields, Tabs } from '../login';
import { LoginTemplateData } from '@/Lib/types/login';
import { ButtonAuth } from '../form/button-auth';
import { ResultMessage } from '../form/result-message';
import { useEffect } from 'react';

type Props = {
  loading: boolean;
  forgetPassHandler: any;
  changeHandler: any;
  errors: TFields;
  fields: TFields;
  ChangeTab: any;
  tabData: LoginTemplateData | null;
  type: string;
  resultMessage: string;
  isForgetLinkSend: boolean;
};

const ForgetPassword = ({
  loading,
  forgetPassHandler,
  changeHandler,
  errors,
  fields,
  ChangeTab,
  tabData,
  type,
  resultMessage,
  isForgetLinkSend,
}: Props) => {
  return (
    <div>
      <div className="px-4 pb-4 md:px-6 md:pb-6">
        <div className={'flex flex-col mt-4 md:mt-6 '}>
          <label className="text-sm font-semibold text-dark-700 text-left leading-4">
            Email address
          </label>
          <Input
            name="email"
            error={errors.email}
            value={fields.email}
            changeHandler={changeHandler}
            errorBorder={true}
            fontColor="text-dark-700"
            classes=" mt-1.5 !rounded-md"
            errorClass=" !text-red-900 !text-2xs font-semibold"
            disabled={true}
          />
        </div>
        {isForgetLinkSend && <ResultMessage message={resultMessage} />}
        <div className="mt-4 md:mt-6  w-full flex flex-col justify-center items-center">
          {!isForgetLinkSend ? (
            <ButtonAuth
              tab={Tabs.forgetPass}
              tabData={tabData}
              onClick={(e: any) => forgetPassHandler(e)}
              loading={loading}
              type={type}
            />
          ) : (
            <div className="w-full bg-[#FFF1D3] rounded-md h-10 flex justify-start px-3 items-center">
              <ul className=" list-disc pl-4">
                <li className="text-dark-700 text-2xs !leading-4 font-normal">
                  If you didn’t get the email please do this process again later
                </li>
              </ul>
            </div>
          )}

          <div
            onClick={() => ChangeTab(Tabs.init)}
            className="mt-4 md:mt-6 text-dark-800 !leading-5 text-base font-semibold underline w-[10.5rem] h-6 flex justify-center items-center cursor-pointer"
          >
            Back
          </div>
        </div>
      </div>
    </div>
  );
};

export { ForgetPassword };
