import { PromotionsState } from '@/Lib/types/promotionts';
import SearchTabs from '@/Components/search-panel/search-tabs';
import { atom, createStore } from 'jotai';

export const globalStateStore = createStore();

export const promotionsAtom = atom<PromotionsState>({});

export const wishlistAtom = atom<number>(0);

export const activeTabAtom = atom<string>(SearchTabs.WORK);

export const userLoginStatusAtom = atom<boolean | null>(null);

export const userEmailAtom = atom<string | null>(null);
