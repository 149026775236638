import { FC } from 'react';
import Link from 'next/link';
import { GImage } from '@/Components/g-image';

const GlobalLogo: FC = () => {
  return (
    <Link href="/">
      <a className="w-30 h-10 relative text-base pointer-events-auto">
        <GImage
          path="website/logo/global-work-and-travel-logo.svg"
          alt="global work and travel logo"
          classes="!object-contain"
        />
      </a>
    </Link>
  );
};

export default GlobalLogo;
