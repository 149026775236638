import { Apple, Facebook, Google } from '../icons';

type Props = {
  brand: string;
  loginWith: any;
};

const SocialButtons = ({ brand, loginWith }: Props) => {
  return (
    <div
      onClick={() => loginWith(brand === 'google' ? 'google-oauth2' : brand)}
      className=" md:m-0 flex justify-center items-center w-[6.5rem] md:w-[7.625rem] gap-x-2.5 w-max-[6.5rem] md:w-max-[7.625rem]  h-[3rem] border border-dark-800  rounded-md cursor-pointer"
    >
      <div className="w-[2.188rem]">
        {brand === 'apple' && Apple()}
        {brand === 'google' && Google()}
        {brand === 'facebook' && Facebook()}
      </div>
    </div>
  );
};

export { SocialButtons };
