import { BookACallContext } from 'layouts/main.layout';
import { useContext, useEffect, useState } from 'react';
import { ModalCentered } from '@/Components/modals/modal-centered';

const appointmentsDomainName =
  process?.env?.NEXT_PUBLIC_APPOINTMENT_DOMAIN_NAME ||
  'appointment.globalworkandtravel.com';

function BookACall(): JSX.Element {
  const bookACallContext = useContext(BookACallContext);
  const [containerWidth, setContainerWidth] = useState<number>(0);
  const [containerHeight, setContainerHeight] = useState<number>(652.5);
  const [windowWidth, setWindowWidth] = useState<number>(0);
  const [startWidth, setStartWidth] = useState<number>(0);

  const env = process.env.NODE_ENV === 'development' ? '-dev' : '';

  useEffect(() => {
    setWindowWidth(window.innerWidth);
    const baseWidth = 960;
    setContainerWidth(windowWidth <= baseWidth ? windowWidth - 20 : baseWidth);
    setStartWidth(containerWidth);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerWidth]);

  useEffect(() => {
    window.addEventListener('message', (e) => {
      if ((e.origin as string).indexOf('appointment') > -1) {
        if (e.data.action === 'width') {
          // @ts-ignore
          const matched = e.data.data.filter((el) => {
            if (el.min != null && el.max != null) {
              return containerWidth >= el.min && containerWidth <= el.max;
            }

            if (el.min != null) {
              return containerWidth >= el.min;
            }

            if (el.max != null) {
              return windowWidth <= el.max && el.width;
            }

            return false;
          });
          setContainerWidth(
            matched.length > 0 && matched[0].width
              ? matched[0].width
              : startWidth
          );
        } else if (e.data.action === 'height' && e.data.data > 300) {
          setContainerHeight(e.data.data);
        } else if (e.data.action === 'close') {
          bookACallContext?.setIsBookACallOpen(false);
        } else if (e.data.action === 'appointment-booked') {
          // dear future reader of this comment,
          // Mazhar copied this over here but he didn't write this comment
          // TODO: Add Rudderstack event for "Book Appointment".
          // const event = {
          //     date: Math.floor(new Date(e.data.date).getTime()/1000),
          //     end: e.data.end,
          //     event_id: e.data.event_id,
          //     staff_id: e.data.staff_id,
          //     start: e.data.start,
          //     timezone: e.data.timezone
          // };
          // this.mixpanelService.track('Book Appointment', event);
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ModalCentered
      isModalOpen={Boolean(bookACallContext?.isBookACallOpen)}
      setIsModalOpen={bookACallContext?.setIsBookACallOpen || (() => {})}
      noCloseButton
      noPadding
    >
      <div
        className="transition-transform duration-500"
        style={{
          maxWidth: containerWidth + 'px',
          width: containerWidth + 'px',
          height: containerHeight + 'px',
        }}
      >
        <iframe
          src={
            bookACallContext?.tripId
              ? `https://${appointmentsDomainName}/schedule?event_id=16&trip_id=${bookACallContext?.tripId}&iframe`
              : `https://${appointmentsDomainName}/schedule?event_id=16&iframe`
          }
          id="appointment-iframe"
          className="iframe-element"
          style={{
            border: 'none',
            width: containerWidth + 'px',
            height: containerHeight + 'px',
          }}
        ></iframe>
      </div>
    </ModalCentered>
  );
}

export { BookACall };
