import { useOffice } from '@/Lib/hooks/useOffice';
import { GetInTouchToggleContext } from 'layouts/main.layout';
import { BookACallContext } from 'layouts/main.layout';
import { useContext, useState } from 'react';
import { Button } from './button';
import { Card } from './card';
import { LiveChatAlert } from './live-chat-alert';
import { OpenClosedDesktop, OpenClosedMobile } from './open-closed';

declare var Intercom: any;

const GetInTouchComponent = () => {
  const getInTouchToggleContext = useContext(GetInTouchToggleContext);
  const { isOpen, phone } = useOffice();
  const bookACallContext = useContext(BookACallContext);
  const [alert, setAlert] = useState(false);

  function openIntercom() {
    try {
      if (Intercom && typeof Intercom !== 'undefined') {
        Intercom('show');
      } else {
        setAlert(true);
      }
    } catch (e) {
      console.error(e);
      setAlert(true);
    }
  }

  function tryOpeningIntercom() {
    openIntercom();
    if (getInTouchToggleContext)
      getInTouchToggleContext.setIsGetInTouchOpen(false);
  }

  return (
    <>
      <LiveChatAlert alert={alert} setAlert={setAlert} />
      <h1 className="md:text-center text-2.5xl md:text-4xl leading-9 font-semibold mb-2.5 md:mb-8">
        Get in touch with us
      </h1>
      <div className="flex flex-col md:flex-row gap-5 lg:gap-10">
        <Card
          onClick={() => tryOpeningIntercom()}
          title="Live chat with us"
          description={
            <>
              If you prefer tapping, our helpful Work & Travel Experts are eager
              and ready to help with any of your questions.
            </>
          }
          image={{
            path: 'website/icons/get-in-touch/live-chat-with-us.svg',
            alt: 'live chat icon',
            width: '100%',
            height: 'auto',
            styles: { alignSelf: 'center', display: 'flex' },
          }}
          status={{
            desktop: <div className="flex-grow"></div>,
          }}
          button={<Button text="Start live chat" isActive />}
        />
        <a href={isOpen ? `tel:${phone}` : undefined}>
          <Card
            title="Give us a ring"
            description={
              <>
                Want to talk about it? So do we. When we’re not sleeping our
                friendly and knowledgeable team are standing by to take your
                call.
              </>
            }
            image={{
              path: 'website/icons/get-in-touch/give-us-a-ring.svg',
              alt: 'call icon',
              width: 'auto',
              height: '100%',
            }}
            status={{
              mobile: <OpenClosedMobile isOpen={isOpen} />,
              desktop: <OpenClosedDesktop isOpen={isOpen} />,
            }}
            button={<Button text={phone} isActive={isOpen} />}
          />
        </a>
        <Card
          onClick={() => {
            getInTouchToggleContext?.setIsGetInTouchOpen(false);
            bookACallContext?.setIsBookACallOpen(true);
          }}
          title="Schedule a call back"
          description={
            <>
              We can call you at a time that suits. Just{' '}
              <span className="md:hidden">tap</span>
              <span className="hidden md:inline">click</span> here to book a
              call back from a Work & Travel Expert,
              <span className="whitespace-nowrap"> 24 hrs </span>Mon-Fri.
            </>
          }
          image={{
            width: '90%',
            height: 'auto',
            path: 'website/icons/get-in-touch/book-a-call.svg',
            alt: 'schedule a call back icon',
            styles: {
              alignSelf: 'center',
              display: 'flex',
              height: '90%',
            },
          }}
          status={{
            desktop: <div className="flex-grow"></div>,
          }}
          button={<Button text="Pick a date & time" isActive />}
        />
      </div>
    </>
  );
};
export { GetInTouchComponent };
