import { TOffice } from '@/Lib/types/office';
import { UserLocation } from '../types/location';
import { useLocation } from './useLocation';

const officeData: {
  [key: string]: TOffice;
} = {
  Australia: {
    id: 1,
    order: 0,
    default: true,
    office: 'Australia',
    name: {
      region: 'Australia & New Zealand',
      legalName: 'Asia Pacific',
    },
    lat: -37.814695,
    lng: 144.963572,
    address: {
      street: 'Level 4, 152 Elizabeth Street',
      city: 'Melbourne',
      state: 'Victoria',
      country: 'Australia',
      postCode: '3000',
    },
    phone: [
      {
        number: '1300 40 11 90',
        countryCode: 'AU',
      },
      {
        number: '09 925 0387',
        countryCode: 'NZ',
      },
    ],
    open: {
      offset: 10,
      hours: 'Monday to Friday <br/> 9am - 6pm AEST',
      times: {
        0: null,
        1: ['09:00', '18:00'],
        2: ['09:00', '18:00'],
        3: ['09:00', '18:00'],
        4: ['09:00', '18:00'],
        5: ['09:00', '18:00'],
        6: null,
      },
    },
  },

  Australia1: {
    id: 4,
    order: 1,
    default: false,
    office: 'Australia',
    name: {
      region: 'Australia & New Zealand',
      legalName: 'Asia Pacific',
    },
    lat: -28.0794438,
    lng: 153.410719,
    address: {
      street: 'Level 2, 194 Varsity Parade',
      city: 'Gold Coast',
      state: 'Queensland',
      country: 'Australia',
      postCode: '4227',
    },
    phone: [
      {
        number: '1300 40 11 90',
        countryCode: 'AU',
      },
      {
        number: '09 925 0387',
        countryCode: 'NZ',
      },
    ],
    open: {
      offset: 10,
      hours: 'Monday to Friday <br/> 9am - 6pm AEST',
      times: {
        0: null,
        1: ['09:00', '18:00'],
        2: ['09:00', '18:00'],
        3: ['09:00', '18:00'],
        4: ['09:00', '18:00'],
        5: ['09:00', '18:00'],
        6: null,
      },
    },
  },

  Canada: {
    id: 2,
    order: 2,
    default: true,
    office: 'Canada',
    name: {
      region: 'Canada & USA',
      legalName: 'North America',
    },
    lat: 49.28531,
    lng: -123.11247,
    address: {
      street: 'Level 8, 333 Seymour Street',
      city: 'Vancouver',
      state: 'British Columbia',
      country: 'Canada',
      postCode: 'V6B 5A6',
    },
    phone: [
      {
        number: '1877 744 5622',
        countryCode: 'AU',
      },
    ],
    open: {
      offset: -8,
      hours: 'Monday to Friday <br/> 9am - 6pm PST',
      times: {
        0: null,
        1: ['06:00', '18:00'],
        2: ['06:00', '18:00'],
        3: ['06:00', '18:00'],
        4: ['06:00', '18:00'],
        5: ['06:00', '18:00'],
        6: null,
      },
    },
  },

  'United Kingdom': {
    id: 3,
    default: true,
    order: 3,
    office: 'United Kingdom',
    name: {
      region: 'UK & Europe',
      legalName: 'Europe',
    },
    lat: 51.518694,
    lng: -0.110139,
    address: {
      street: '3 Waterhouse Square, 138 Holborn',
      city: 'London',
      state: 'England',
      country: 'UK',
      postCode: 'EC1N 2SW',
    },
    phone: [
      {
        number: '020 3002 2138',
        countryCode: 'UK',
      },
      {
        number: '+44 20 3002 2138',
        countryCode: 'EU',
      },
      {
        number: '02 320 55 07',
        countryCode: 'BE',
      },
      {
        number: '020 532 0028',
        countryCode: 'NL',
      },
      {
        number: '01 513 5536',
        countryCode: 'IE',
      },
    ],
    open: {
      offset: 1,
      hours: 'Monday to Friday <br/> 9am - 6pm GMT',
      times: {
        0: null,
        1: ['09:00', '18:00'],
        2: ['09:00', '18:00'],
        3: ['09:00', '18:00'],
        4: ['09:00', '18:00'],
        5: ['09:00', '18:00'],
        6: null,
      },
    },
  },

  Worldwide: {
    id: -1,
    order: 4,
    default: false,
    office: 'Worldwide',
    name: {
      region: 'Worldwide',
      legalName: '',
    },
    phone: [
      {
        number: '+61 7 5528 4813',
        countryCode: 'AU',
      },
    ],
    open: {
      offset: 10,
      hours: 'Monday to Friday <br/> 9am - 6pm AEST',
      times: {
        0: null,
        1: ['09:00', '18:00'],
        2: ['09:00', '18:00'],
        3: ['09:00', '18:00'],
        4: ['09:00', '18:00'],
        5: ['09:00', '18:00'],
        6: null,
      },
    },
  },
};

const phoneNumbers: {
  [key: string]: string;
} = {
  Americas: '1877 744 5622',
  Europe: '(+44) 20 3002 2138',
  Ocenia: '(+61) 7 5528 4813',
  GB: '020 3002 2138',
  AU: '1300 40 11 90',
  NZ: '09 925 0387',
  BE: '02 320 55 07',
  NL: '020 532 0028',
  IE: '01 513 5536',
  default: '(+61) 7 5528 4813',
};

const offices: {
  [key: string]: string;
} = {
  Americas: 'Canada',
  Europe: 'United Kingdom',
  Ocenia: 'Australia',
  default: 'Australia',
};

function timeIsValid(
  time: string,
  now: string,
  comparitor: (a: number, b: number) => boolean
) {
  const [timeHours, timeMinutes] = time.split(':').map(Number);
  const [nowHours, nowMinutes] = now.split(':').map(Number);
  const open =
    timeHours === nowHours
      ? comparitor(timeMinutes, nowMinutes)
      : comparitor(timeHours, nowHours);
  return open;
}

function isOfficeOpen(office: TOffice): boolean {
  const now = new Date(
    new Date().getTime() + office.open.offset * 60 * 60 * 1000
  );
  // @ts-ignore
  const day = office.open.times[now.getUTCDay()];
  if (!day) {
    return false;
  }

  const [openTime, closeTime] = day;

  const currentTime = `${String(now.getUTCHours())}:${String(
    now.getUTCMinutes()
  )}`;

  return (
    timeIsValid(openTime, currentTime, (a, b) => a <= b) &&
    timeIsValid(closeTime, currentTime, (a, b) => a >= b)
  );
}

function getPhone(location: UserLocation): string {
  const phone: string =
    phoneNumbers[location.country.code] ||
    phoneNumbers[location.continent] ||
    phoneNumbers.default;
  return phone;
}

function getOfficeName(location: UserLocation): string {
  const officeName: string = offices[location.continent] || offices.default;
  return officeName;
}

export type useOfficeReturnDataType = {
  isOpen: boolean;
  phone: string;
  office: TOffice;
};

function useOffice(): useOfficeReturnDataType {
  const { location }: { location: UserLocation } = useLocation();

  const phone = getPhone(location);

  const officeName = getOfficeName(location);

  const office = officeData[officeName];

  const isOpen = isOfficeOpen(office);

  return {
    isOpen,
    phone,
    office,
  };
}

export type useAllOfficesReturnDataType = {
  office: TOffice;
  isOpen: boolean;
}[];

function useAllOffices(): useAllOfficesReturnDataType {
  const allOffices: { office: TOffice; isOpen: boolean }[] = [];
  for (let office in officeData) {
    allOffices.push({
      office: officeData[office],
      isOpen: isOfficeOpen(officeData[office]),
    });
  }
  return allOffices;
}

export { useOffice, useAllOffices };
