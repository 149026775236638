import { TCurrency } from '@/Lib/types/currency';

export const currencies: {
  [key: string]: TCurrency;
} = {
  Americas: 'USD',
  Europe: 'EUR',
  Ocenia: 'AUD',
  AU: 'AUD',
  CA: 'CAD',
  US: 'USD',
  GB: 'GBP',
  NZ: 'NZD',
  default: 'USD',
};

export const currencySymbols = {
  AUD: '$',
  CAD: '$',
  EUR: '€',
  GBP: '£',
  NZD: '$',
  USD: '$',
};
