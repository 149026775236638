class RudderstackHelper {
  static getRudderstackConfig() {
    const rudderstackUrl = process?.env?.NEXT_PUBLIC_RUDDER_STACK_URL;
    const rudderstackWriteKey =
      process?.env?.NEXT_PUBLIC_RUDDER_STACK_WRITE_KEY;
    return { writeKey: rudderstackWriteKey, url: rudderstackUrl };
  }
}

export { RudderstackHelper };
