import { BasicTrip, Trip, Promotion } from '@/Lib/types/trip';

interface PromoValidator {
  validate: () => boolean;
}

/**
 * The purpose of this class is to have a set of functions that easily identifies
 * the validations needed for a promotion. This is needed for promotions applied via
 * the banner because the promotion may be initiated anywhere within the site. Once
 * it comes time to validate it against a trip, this convenience class can be used.
 */
class TripValidator implements PromoValidator {
  p: any;
  trip: Trip | BasicTrip;

  /**
   * @param { TripPromotion } p - Promotion to validate against
   * @param { FullTrip | BasicTrip } trip - Trip to validate against
   */
  constructor(p: Promotion, trip: Trip | BasicTrip) {
    this.p = p;
    this.trip = trip;
  }

  /**
   * Initiate the validations and return if there is any false
   * @returns { boolean } - If there is any false validations
   */
  validate(): boolean {
    const validations = [this.division(), this.destination(), this.tripCode()];
    return validations.filter((v) => v == false).length == 0;
  }

  /**
   * Validates that the division on the promotion equals the trips division
   * @returns { boolean } - Valid or not
   */
  division(): boolean {
    if (this.p.division) {
      return (
        (this.p.division as string)
          .split(',')
          .map((d) => d.trim())
          .indexOf(this.trip.division) > -1
      );
    } else {
      return true;
    }
  }

  /**
   * Validates that the division on the promotion equals the trips division
   * @returns { boolean } - Valid or not
   */
  destination(): boolean {
    if (this.p.destination) {
      return this.trip.destination === this.p.destination;
    } else {
      return true;
    }
  }

  /**
   * Validates against the trip code
   * @returns { boolean } - Valid or not
   */
  tripCode(): boolean {
    if (this.p.tripCode) {
      if (!this.trip.code) {
        return false;
      }
      // Checks that the code array on the promotion contains the trip code
      const codeArr = this.p.tripCode.split(',');
      const hasCode = codeArr.indexOf(this.trip.code);
      if (hasCode === -1) {
        return false;
      }
    }
    return true;
  }
}

/**
 * A convenience class to validate that a promotion hasn't expired.
 */
class ExpiryValidator implements PromoValidator {
  p: Promotion;

  constructor(p: Promotion) {
    this.p = p;
  }

  // Convert to UTC and ensure it has started and is not expired
  validate(): boolean {
    const now = Math.floor(Date.now() / 1000);
    return now > this.p.init && now < this.p.expires;
  }
}

export { TripValidator, ExpiryValidator };
