import Head from 'next/head';
import { useRouter } from 'next/router';
import { TMeta } from '@/Lib/types/merketing';

type Props = {
  data: TMeta;
};

const Meta = ({ data }: Props) => {
  const router = useRouter();
  const path = router.asPath.split('?')[0];

  return (
    <Head>
      <title>{data?.title}</title>
      <meta key="description" name="description" content={data.description} />
      <link
        rel="canonical"
        href={'https://www.globalworkandtravel.com' + path}
      />
      {data.hasNoIndex ? (
        <meta key="robots" name="robots" content="NOINDEX, FOLLOW" />
      ) : (
        <meta key="robots" name="robots" content="INDEX, FOLLOW" />
      )}
      <meta
        key="og:url"
        name="og:url"
        content={'https://www.globalworkandtravel.com' + path}
      />
      <meta key="og:title" name="og:title" content={data.title} />
      <meta
        key="og:description"
        name="og:description:"
        content={data.description}
      />
      <meta key="og:image" name="og:image" content={data.image} />
      <meta
        key="twitter:card"
        name="twitter:card"
        content="summary_large_image"
      />
      <meta key="twitter:site" name="twitter:site" content="@TheGWATCo" />
      <meta key="twitter:creator" name="twitter:creator" content="@TheGWATCo" />
      <meta key="twitter:title" name="twitter:title" content={data.title} />
      <meta
        key="twitter:description"
        name="twitter:description"
        content={data.description}
      />
      <meta key="twitter:image" name="twitter:image" content={data.image} />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, viewport-fit=cover, maximum-scale=1"
      />
      <link rel="icon" href="/favicon.ico" />
    </Head>
  );
};

export { Meta };
