import { useEffect, useState } from 'react';
import {
  getEmailValidation,
  getLengthPassCharValidation,
  getNameValidation,
  getNumberValidation,
  getStrongPasswordValidation,
} from '@/Lib/helpers/validation';
import {
  auth,
  loginWithSocial,
  rudderStackIdentify,
} from '@/Lib/helpers/auth0';
import { useCookies } from 'react-cookie';
import 'react-toastify/dist/ReactToastify.css';
import { ToastError, ToastSuccess } from '../toast';
import { useDevice } from '@/Lib/hooks/useDevice';
import { MainFpdcLayout } from './form/mainLayout';
import { InitTab } from './tabs/init';
import { useMutation, useQuery } from '@apollo/client';
import {
  CHECKEMAIL_QUERY,
  LOGIN_QUERY,
  REGISTER_QUERY,
} from '@/Lib/graphql/queries/auth0.query';
import { RegisterTab } from './tabs/register';
import { LoginTab } from './tabs/login';
import { LoginSocial } from './tabs/login-social';
import { ForgetPassword } from './tabs/forget-password';
import { SingleValue } from 'react-select';
import { TDropDownItem } from '../drop-down/types';
import { LoginModal } from './login-modal';
import { useSetAtom } from 'jotai';
import {
  userEmailAtom,
  userLoginStatusAtom,
} from '@/State/global/global.store';
import { LOGIN_TEMPLATE_COLLECTION } from '@/Lib/graphql/queries/login-template-collection';
import { LoginTemplateData } from '@/Lib/types/login';
import { RudderAnalytics } from '../ruddarstak/rudderanalytics';
import { useLocation } from '@/Lib/hooks/useLocation';

type props = {
  isOpen: boolean;
  setIsOpen: Function;
  AfterLogin: Function;
  type: string;
};

export type TFields = {
  password: string;
  email: string;
  firstName: string;
  lastName: string;
  phoneCode: string;
  phoneNumber: string;
};

export enum Tabs {
  login = 'login',
  register = 'register',
  forgetPass = 'forget-pass',
  init = 'init-form',
  loginSocial = 'social-login',
}

function Login({ isOpen, setIsOpen, AfterLogin, type }: props): JSX.Element {
  const { location } = useLocation();
  const setUserEmail = useSetAtom(userEmailAtom);
  const [resultMessage, setResultMessage] = useState('');
  const [cookies, setCookie, removeCookie] = useCookies([
    'auth',
    'user-login-status',
  ]);
  const [tab, setTab] = useState<Tabs>(Tabs.init);
  const [passwordType, setPasswordType] = useState('password');
  const [loading, setLoading] = useState(false);
  const { isMD } = useDevice();
  const [disabledEmail, setDisabledEmail] = useState(false);
  const [selectedCountryCode, setSelectedCountryCode] =
    useState<SingleValue<TDropDownItem>>(null);
  const setUserLoginStatus = useSetAtom(userLoginStatusAtom);
  const cacheControlHeader = {
    headers: {
      'Cache-Control': 'max-age=0',
    },
  };
  const [fields, setFields] = useState<TFields>({
    password: '',
    email: '',
    firstName: '',
    lastName: '',
    phoneCode: '',
    phoneNumber: '',
  });

  const [errors, setErrors] = useState<TFields>({
    password: '',
    email: '',
    firstName: '',
    lastName: '',
    phoneCode: '',
    phoneNumber: '',
  });
  const [tabData, setTabData] = useState<LoginTemplateData | null>(null);
  const [isForgetLinkSend, setIsForgetLinkSend] = useState(false);
  const [
    checkEmail,
    {
      loading: checkEmailLoading,
      data: checkEmailData,
      error: checkEmailError,
    },
  ] = useMutation<{
    checkEmail: {
      isSocial: boolean;
      success: boolean;
      code: string;
      message: string;
    };
  }>(CHECKEMAIL_QUERY);

  const [
    loginAuth0,
    {
      loading: loginAuth0Loading,
      data: loginAuth0Data,
      error: loginAuth0Error,
    },
  ] = useMutation<{
    login: {
      success: boolean;
      accessToken: string;
      firstName: string;
      lastName: string;
      email: string;
      phoneCode: string;
      phoneNumber: string;
      statusCode: string;
      code: string;
      message: string;
    };
  }>(LOGIN_QUERY);

  const {
    loading: loadingTemplate,
    data: dataTemplate,
    error: dataTemplateError,
  } = useQuery<LoginTemplateData>(LOGIN_TEMPLATE_COLLECTION);

  const [
    registerAuth0,
    { loading: registerLoading, data: registerData, error: registerError },
  ] = useMutation<{
    register: {
      success: boolean;
      accessToken: string;
      firstName: string;
      lastName: string;
      email: string;
      phoneCode: string;
      phoneNumber: string;
      statusCode: string;
      code: string;
      message: string;
    };
  }>(REGISTER_QUERY);

  function setContext() {
    setUserLoginStatus(true);
  }

  function changeHandler(e: React.FormEvent<HTMLInputElement>) {
    e.preventDefault();
    e.stopPropagation();
    setFields({
      ...fields,
      // @ts-ignore
      [e.target.name]: e.target.value,
    });
    // @ts-ignore
    checkFields(e.target.name, e.target.value);
  }

  function checkFields(field?: string, fieldValue?: string): boolean {
    let response = errors;

    if (
      tab === Tabs.register &&
      (!field || (field && field === 'firstName')) &&
      (field ? fieldValue?.length : fields.firstName.length) === 0
    ) {
      response = { ...response, firstName: 'This field is required' };
    } else if (!field || (field && field === 'firstName')) {
      response = { ...response, firstName: '' };
    }

    if (
      tab === Tabs.register &&
      (!field || (field && field === 'firstName')) &&
      ((field ? fieldValue?.length : fields.firstName.length) || 0 > 0) &&
      !getNameValidation((field ? fieldValue : fields.firstName) || '')
    ) {
      response = {
        ...response,
        firstName: 'Please provide a valid name',
      };
    } else if (
      (!field || (field && field === 'firstName')) &&
      ((field ? fieldValue?.length : fields.firstName.length) || 0 > 0)
    ) {
      response = { ...response, firstName: '' };
    }

    if (
      tab === Tabs.register &&
      (!field || (field && field === 'lastName')) &&
      (field ? fieldValue?.length : fields.lastName.length) === 0
    ) {
      response = { ...response, lastName: 'This field is required' };
    } else if (!field || (field && field === 'lastName')) {
      response = { ...response, lastName: '' };
    }

    if (
      tab === Tabs.register &&
      (!field || (field && field === 'lastName')) &&
      ((field ? fieldValue?.length : fields.lastName.length) || 0 > 0) &&
      !getNameValidation((field ? fieldValue : fields.lastName) || '')
    ) {
      response = { ...response, lastName: 'Please provide a valid last name' };
    } else if (
      (!field || (field && field === 'lastName')) &&
      ((field ? fieldValue?.length : fields.lastName.length) || 0 > 0)
    ) {
      response = { ...response, lastName: '' };
    }

    if (
      tab === Tabs.register &&
      (!field || (field && field === 'phoneNumber')) &&
      ((field ? fieldValue?.length : fields.phoneNumber.length) || 0 > 0) &&
      !getNumberValidation((field ? fieldValue : fields.phoneNumber) || '')
    ) {
      response = {
        ...response,
        phoneNumber: 'Please provide a valid phone number',
      };
    } else if (!field || (field && field === 'phoneNumber')) {
      response = { ...response, phoneNumber: '' };
    }

    if (
      (field ? fieldValue?.length : fields.email.length) === 0 &&
      (!field || (field && field === 'email'))
    ) {
      response = { ...response, email: 'This field is required' };
    } else if (!field || (field && field === 'email')) {
      response = { ...response, email: '' };
    }

    if (
      !getEmailValidation(field ? fieldValue || '' : fields.email) &&
      (!field || (field && field === 'email'))
    ) {
      response = {
        ...response,
        email: 'Please provide a valid email address',
      };
    } else if (!field || (field && field === 'email')) {
      response = {
        ...response,
        email: '',
      };
    }

    if (
      tab !== Tabs.forgetPass &&
      (!field || (field && field === 'password')) &&
      tab !== Tabs.init &&
      (field ? fieldValue?.length : fields.password.length) === 0
    ) {
      response = {
        ...response,
        password: 'This field is required',
      };
    } else if (!field || (field && field === 'password')) {
      response = {
        ...response,
        password: '',
      };
    }

    if (
      tab !== Tabs.forgetPass &&
      tab !== Tabs.init &&
      (!field || (field && field === 'password')) &&
      !getLengthPassCharValidation(field ? fieldValue || '' : fields.password)
    ) {
      response = {
        ...response,
        password: 'Must have 6 characters in length',
      };
    } else if (!field || (field && field === 'password')) {
      response = {
        ...response,
        password: '',
      };
    }

    setErrors(response);
    if (
      !response.email &&
      !response.password &&
      !response.firstName &&
      !response.lastName &&
      !response.phoneCode &&
      !response.phoneNumber
    ) {
      return false;
    }
    return true;
  }

  function registerHandler(e: React.MouseEvent<HTMLElement>) {
    e.preventDefault();
    e.stopPropagation();
    const hasErrors = checkFields();
    if (hasErrors) return;
    setLoading(true);
    registerAuth0({
      variables: {
        email: fields.email,
        password: fields.password,
        firstName: fields.firstName,
        lastName: fields.lastName,
        phoneCode: selectedCountryCode?.value,
        phone: fields.phoneNumber,
      },
    });
  }

  const resetForm = () => {
    setFields({
      lastName: '',
      firstName: '',
      email: '',
      password: '',
      phoneCode: '',
      phoneNumber: '',
    });
    setErrors({
      lastName: '',
      firstName: '',
      email: '',
      password: '',
      phoneCode: '',
      phoneNumber: '',
    });
    setPasswordType('password');
    setIsForgetLinkSend(false);
  };

  function loginHandler(e: React.MouseEvent<HTMLElement>) {
    e.preventDefault();
    e.stopPropagation();
    const hasErrors = checkFields();
    if (hasErrors) return;
    setLoading(true);
    loginAuth0({
      variables: {
        email: fields.email,
        password: fields.password,
      },
      context: cacheControlHeader,
    });
  }

  function forgetPassHandler(e?: React.MouseEvent<HTMLElement>) {
    e?.preventDefault();
    e?.stopPropagation();
    const hasErrors = checkFields();
    if (hasErrors) return;
    setLoading(true);
    auth.changePassword(
      {
        connection: 'Username-Password-Authentication',
        email: fields.email,
      },
      function (err: any, res: any) {
        setLoading(false);
        if (err) {
          ToastError(
            'Something went wrong. Please try later.',
            !isMD as boolean
          );
        } else {
          // ToastSuccess(res, isMobile as boolean);
          setIsForgetLinkSend(true);
          setResultMessage(res);
        }
      }
    );
  }

  const LoginWith = (brand: string) => {
    loginWithSocial(brand)
      .then((email) => {
        ToastSuccess('Login successful', !isMD as boolean);
        if (type === 'subscribe-init-form') {
          RudderAnalytics.newsletterSubscribed({
            country: location.country.name,
            email: email || '',
            platform: 'website',
          });
        }
        resetForm();
        setIsOpen(false);
        setContext();
        AfterLogin();
      })
      .catch((err) => {
        if (err.original !== 'User closed the popup window') {
          ToastError(
            'Something went wrong. Please try later.',
            !isMD as boolean
          );
        }
      });
  };

  const ChangeTab = (tab: string) => {
    setTab(tab as Tabs);
    if (tab === Tabs.init) {
      setIsForgetLinkSend(false);
    }
  };

  const checkInitEmail = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const hasErrors = checkFields();
    if (hasErrors) return;
    setLoading(true);

    checkEmail({
      variables: {
        email: fields.email,
      },
      context: cacheControlHeader,
    });
  };

  useEffect(() => {
    if (isOpen == false) {
      resetForm();
      setTab(Tabs.init);
    }
  }, [isOpen]);

  useEffect(() => {
    if (!checkEmailLoading && checkEmailError) {
      setLoading(false);
      ToastError('Something went wrong, please try later...', !isMD as boolean);
    }

    if (!checkEmailLoading && !checkEmailError && checkEmailData) {
      setLoading(false);
      if (checkEmailData.checkEmail.code && checkEmailData.checkEmail.message) {
        ToastError(checkEmailData.checkEmail.message, !isMD as boolean);
        return;
      }

      if (checkEmailData.checkEmail.success) {
        if (checkEmailData.checkEmail.isSocial) {
          setTab(Tabs.loginSocial);
        } else {
          setTab(Tabs.login);
          setDisabledEmail(true);
        }
      } else {
        setTab(Tabs.register);
        setDisabledEmail(true);
      }
    }
  }, [checkEmailLoading, checkEmailData, checkEmailError]);

  useEffect(() => {
    if (!registerLoading && registerError) {
      setLoading(false);
      ToastError('Something went wrong, please try later...', !isMD as boolean);
    }

    if (!registerLoading && !registerError && registerData) {
      setLoading(false);
      if (registerData.register.code && registerData.register.message) {
        ToastError(registerData.register.message, !isMD as boolean);
        return;
      }

      if (registerData.register.success) {
        ToastSuccess('Login successful', !isMD as boolean);
        setCookie('auth', {
          accessToken: registerData.register.accessToken,
        });
        rudderStackIdentify(
          registerData.register.firstName,
          registerData.register.lastName,
          registerData.register.email,
          registerData.register.phoneCode,
          registerData.register.phoneNumber
        );
        if (type === 'subscribe-init-form') {
          RudderAnalytics.newsletterSubscribed({
            country: location.country.name,
            email: registerData.register.email || '',
            platform: 'website',
          });
        }
        setUserEmail(registerData.register.email);
        resetForm();
        setIsOpen(false);
        setContext();
        AfterLogin();
      } else {
        ToastError(registerData.register.message, !isMD as boolean);
      }
    }
  }, [registerLoading, registerData, registerError]);

  useEffect(() => {
    if (!loginAuth0Loading && loginAuth0Error) {
      setLoading(false);
      ToastError('Something went wrong, please try later...', !isMD as boolean);
    }

    if (!loginAuth0Loading && !loginAuth0Error && loginAuth0Data) {
      setLoading(false);

      if (loginAuth0Data.login.code && loginAuth0Data.login.message) {
        ToastError(loginAuth0Data.login.message, !isMD as boolean);

        return;
      }

      if (loginAuth0Data.login.success) {
        ToastSuccess('Login successful', !isMD as boolean);

        setCookie('auth', {
          accessToken: loginAuth0Data.login.accessToken,
        });

        rudderStackIdentify(
          loginAuth0Data.login.firstName,
          loginAuth0Data.login.lastName,
          loginAuth0Data.login.email,
          loginAuth0Data.login.phoneCode,
          loginAuth0Data.login.phoneNumber
        );
        if (type === 'subscribe-init-form') {
          RudderAnalytics.newsletterSubscribed({
            country: location.country.name,
            email: loginAuth0Data.login.email || '',
            platform: 'website',
          });
        }
        setUserEmail(loginAuth0Data.login.email);
        resetForm();
        setIsOpen(false);
        AfterLogin();
        setContext();
      } else {
        ToastError(loginAuth0Data.login.message, !isMD as boolean);
      }
    }
  }, [loginAuth0Loading, loginAuth0Data, loginAuth0Error]);

  useEffect(() => {
    if (!loadingTemplate && dataTemplate) {
      setTabData(dataTemplate);
    }
  }, [loadingTemplate, dataTemplate, dataTemplateError]);

  return (
    <LoginModal isOpen={isOpen} setIsOpen={setIsOpen} isMobile={!isMD}>
      <MainFpdcLayout
        tab={tab}
        setIsOpen={setIsOpen}
        resultMessage={resultMessage}
        tabData={tabData}
        type={type}
      >
        {tab === Tabs.init && (
          <InitTab
            loading={loading}
            loginWith={(brand: string) => LoginWith(brand)}
            checkEmail={(e: React.MouseEvent<HTMLInputElement>) =>
              checkInitEmail(e)
            }
            changeHandler={(e: React.FormEvent<HTMLInputElement>) =>
              changeHandler(e)
            }
            errors={errors}
            fields={fields}
            tabData={tabData}
            type={type}
          />
        )}
        {tab === Tabs.register && (
          <RegisterTab
            loading={loading}
            changeHandler={(e: React.FormEvent<HTMLInputElement>) =>
              changeHandler(e)
            }
            errors={errors}
            fields={fields}
            passwordType={passwordType}
            setPasswordType={setPasswordType}
            registerHandler={(e: React.MouseEvent<HTMLElement>) =>
              registerHandler(e)
            }
            disabledEmail={disabledEmail}
            setSelectedCountryCode={setSelectedCountryCode}
            tabData={tabData}
            selectedCountryCode={selectedCountryCode}
            type={type}
          />
        )}
        {tab === Tabs.login && (
          <LoginTab
            loading={loading}
            changeHandler={(e: React.FormEvent<HTMLInputElement>) =>
              changeHandler(e)
            }
            errors={errors}
            fields={fields}
            passwordType={passwordType}
            setPasswordType={setPasswordType}
            loginHandler={(e: React.MouseEvent<HTMLElement>) => loginHandler(e)}
            ChangeTab={(tab: string) => ChangeTab(tab)}
            disabledEmail={disabledEmail}
            tabData={tabData}
            type={type}
          />
        )}
        {tab === Tabs.loginSocial && (
          <LoginSocial loginWith={(brand: string) => LoginWith(brand)} />
        )}
        {tab === Tabs.forgetPass && (
          <ForgetPassword
            loading={loading}
            forgetPassHandler={(e: React.MouseEvent<HTMLElement>) =>
              forgetPassHandler(e)
            }
            changeHandler={(e: React.FormEvent<HTMLInputElement>) =>
              changeHandler(e)
            }
            errors={errors}
            fields={fields}
            ChangeTab={(tab: string) => ChangeTab(tab)}
            tabData={tabData}
            type={type}
            resultMessage={resultMessage}
            isForgetLinkSend={isForgetLinkSend}
          />
        )}
      </MainFpdcLayout>
    </LoginModal>
  );
}

export { Login };
