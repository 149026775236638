import { GImage } from '@/Components/g-image';
import { useDevice } from '@/Lib/hooks/useDevice';
import { HeaderData } from '@/Lib/types/header';

type Props = {
  headerData: HeaderData;
};

const SocialProof = ({ headerData }: Props) => {
  const { isLG } = useDevice();
  const imageNames = [1, 2, 3, 4];

  return (
    <div className="flex items-center gap-1 flex-1-only">
      <div className="flex">
        {imageNames.map((imageName, index) => (
          <div
            key={index}
            className="rounded-full overflow-hidden relative border-2 border-light-600"
            style={{ left: index * -6 }}
          >
            <GImage
              width={isLG ? '1.5rem' : '1rem'}
              height={isLG ? '1.5rem' : '1rem'}
              path={`website/static/header-social-proof/${imageName}`}
              alt={`generic avatar ${imageName}`}
            />
          </div>
        ))}
      </div>
      <p className="font-semibold text-3xs md:text-sm leading-[0.625rem] text-dark-700 relative -left-5 md:-left-4 mt-px">
        Join {headerData.config.data?.config.tripCount?.toLocaleString()}+
        global travellers
      </p>
    </div>
  );
};

export { SocialProof };
