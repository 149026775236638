export function toggleFavorite(tripId: number): boolean {
  if (window) {
    const favorites = getFavorites();
    if (favorites.length > 0) {
      // if found in favs, must remove
      if (favorites.find((item) => item === tripId)) {
        const updatedFavorites = favorites.filter((item) => item !== tripId);
        window.localStorage.setItem(
          'gwat-favorites',
          JSON.stringify(updatedFavorites)
        );
        return true;
      } else {
        // if not found, add
        favorites.push(tripId);
        window.localStorage.setItem(
          'gwat-favorites',
          JSON.stringify(favorites)
        );
        return true;
      }
    } else {
      window.localStorage.setItem('gwat-favorites', JSON.stringify([tripId]));
      return true;
    }
  }
  return false;
}

export function getFavorites(): number[] {
  if (window) {
    const favorites = JSON.parse(
      String(window.localStorage.getItem('gwat-favorites'))
    );
    return favorites || [];
  }
  return [];
}
