import { useEffect, useState } from 'react';
import usePromotions from '@/State/hooks/promotions/promotions.hook';
import { useRouter } from 'next/router';
import { useSetAtom } from 'jotai';
import { promotionsAtom } from '@/State/global/global.store';

const useSetPromotions = () => {
  const promotions = usePromotions();
  const router = useRouter();
  const [queryStringPromo, setQueryStringPromo] = useState<string>('');
  const setPromotions = useSetAtom(promotionsAtom);

  useEffect(() => {
    setPromotions(promotions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promotions]);

  // once the url changes, must check for promo code, and if provided,
  // it's sent down to <PromotionsProvider /> to be set into its context
  useEffect(() => {
    const promoQueryParameter = router.query.promo;
    if (promoQueryParameter) {
      setQueryStringPromo(String(promoQueryParameter));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.query]);

  useEffect(() => {
    if (promotions.initializePromotions) {
      promotions.initializePromotions(queryStringPromo);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promotions.initializePromotions, queryStringPromo]);
};

export { useSetPromotions };
