import { ApolloClient, from, HttpLink, InMemoryCache } from '@apollo/client';
import { useMemo } from 'react';
import { onError } from '@apollo/client/link/error';

let apolloClient: ApolloClient<any>;

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
          locations
        )}, Path: ${path}`
      )
    );

  if (networkError) console.log(`[Network error]: ${networkError}`);

  console.log('[Query details]:', {
    name: operation.operationName,
    variables: operation.variables,
  });
});

function createApolloClient() {
  const httpLink = new HttpLink({
    uri:
      'https://sls-staging.globalworkandtravel.com/' ||
      `https://${process?.env?.NEXT_PUBLIC_WEBSITE_API_URL}` ||
      'https://gql.globalworkandtravel.com/',
  });

  return new ApolloClient({
    ssrMode: typeof window === 'undefined', // set to true for SSR
    link: from([errorLink, httpLink]),
    connectToDevTools: true,
    cache: new InMemoryCache(),
  });
}

export function initializeApollo() {
  const _apolloClient = apolloClient ?? createApolloClient();

  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;
  return _apolloClient;
}

export function useApollo() {
  return useMemo(() => initializeApollo(), []);
}
