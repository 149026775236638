import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';

const customModalStyles = {
  overlay: {
    zIndex: 1000,
    backgroundColor: 'transparent',
  },
  content: {
    border: 'none',
    backgroundColor: 'transparent',
  },
};

type ModalCenteredProps = {
  isModalOpen: boolean;
  setIsModalOpen: Function;
  children: JSX.Element | JSX.Element[];
  hasAlternativeWidth?: boolean;
  hasFixedWidth?: boolean;
  noCloseButton?: boolean;
  noPadding?: boolean;
  altBackgroundColor?: string;
  hasPreventDefault?: boolean;
  hasMdRoudedBorders?: boolean;
  hasMoreMargin?: boolean;
  hasOverflowYVisible?: boolean;
  bgDark?: number;
  paddingClass?: string;
  closeClickOut?: boolean;
  zIndex?: number;
};

function ModalCentered({
  isModalOpen,
  setIsModalOpen,
  children,
  hasAlternativeWidth = false,
  hasFixedWidth = false,
  noCloseButton = false,
  noPadding = false,
  altBackgroundColor = '',
  hasPreventDefault = true, // will be set to false by get-in-touch "Give us a ring" to allow <a> open user phone app
  hasMdRoudedBorders = false,
  hasMoreMargin = false,
  hasOverflowYVisible = false,
  bgDark = 900,
  paddingClass = 'p-6 lg:px-14 lg:py-11',
  closeClickOut = true,
  zIndex = 1000,
}: ModalCenteredProps): JSX.Element {
  customModalStyles.overlay.zIndex = zIndex;

  const [shouldBlur, setShouldBlur] = useState<boolean>(false);
  const closeModal = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsModalOpen(false);
  };

  useEffect(() => {
    setTimeout(() => {
      setShouldBlur(isModalOpen);
    }, 100);
  }, [isModalOpen]);

  useEffect(() => {
    Modal.setAppElement('#__next');
    if (isModalOpen) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }

    return () => {
      // make sure overflow-hidden is removed from body once dismounted
      // this usually happens when user clicks on browser back button instead
      // of closing the dialog like a normal human being!!! Just kidding though!
      document.body.classList.remove('overflow-hidden');
    };
  }, [isModalOpen]);

  return (
    <Modal isOpen={isModalOpen} style={customModalStyles} closeTimeoutMS={300}>
      <div
        className={
          `fixed inset-0 w-full h-full z-50 bg-dark-${bgDark} bg-opacity-20 flex justify-center items-center backdrop-blur-[3px]` +
          (shouldBlur ? ' backdrop-opacity-100' : ' backdrop-opacity-0')
        }
        onClick={(e) => {
          e.stopPropagation();
          closeClickOut && closeModal(e);
        }}
        style={{
          transition: 'backdrop-filter 0.3s ease-in-out',
        }}
      >
        <div
          className={
            'relative  max-h-100vh box-border flex flex-col text-dark-900 w-full' +
            (noPadding ? '' : ` ${paddingClass} `) +
            (hasAlternativeWidth ? ' md:w-auto md:max-w-60.625 md:mx-7' : '') +
            (hasFixedWidth ? ' sm:w-105' : ' w-auto') +
            (altBackgroundColor ? ` ${altBackgroundColor}` : ' bg-light-600') +
            (hasMdRoudedBorders ? ' rounded-md' : ' rounded-sm ') +
            (hasMoreMargin ? ' m-5 sm:m-0' : ' m-2 sm:m-0') +
            (hasOverflowYVisible ? ' overflow-y-visible' : ' overflow-y-auto')
          }
          onClick={(e) => {
            if (hasPreventDefault) {
              e.preventDefault();
            }
            e.stopPropagation();
          }}
        >
          {noCloseButton ? null : (
            <i
              className="absolute top-6 right-6 icon-close self-end cursor-pointer"
              onClick={(e) => {
                closeModal(e);
              }}
            ></i>
          )}
          <>{children}</>
        </div>
      </div>
    </Modal>
  );
}

export { ModalCentered };
