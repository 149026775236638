import { LoginTemplateData } from '@/Lib/types/login';
import { Tabs } from '../login';

type Props = {
  tab: Tabs;
  tabData: LoginTemplateData | null;
  loading: boolean;
  onClick: any;
  type: string;
};

const defaultText = (tab: Tabs) => {
  switch (tab) {
    case Tabs.init:
      return 'Continue';
      break;

    case Tabs.login:
      return 'Login';
      break;

    case Tabs.register:
      return 'Create an account';
      break;

    case Tabs.forgetPass:
      return 'Send';
      break;

    default:
      break;
  }
};

const ButtonAuth = ({ tab, tabData, loading, onClick, type }: Props) => {
  const returnTabData = (tab: string) => {
    const data = tabData?.loginTemplateCollection.items.filter(
      (item) => item.type === (tab === Tabs.init ? type : tab)
    );
    if (data) {
      return data[0];
    } else {
      return null;
    }
  };

  return (
    <>
      <button
        className={
          'h-[3.125rem] bg-red-900 text-light-600 rounded-md text-lg leading-5 font-semibold w-full button-load '
        }
        type="button"
        onClick={onClick}
      >
        {loading === false &&
          (returnTabData(tab)?.buttonTitle || defaultText(tab))}
        {loading === true && <span className="load-circle"></span>}
      </button>
    </>
  );
};
export { ButtonAuth };
