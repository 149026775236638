import { ReactNode } from 'react';
import { useSetPromotions } from '../hooks/promotions/useSetPromotions';
import { useSetUserLoginStatus } from '../hooks/user-login-status';

type Props = {
  children: ReactNode;
};

const SetterProvider = ({ children }: Props) => {
  useSetPromotions();
  useSetUserLoginStatus();

  return <>{children}</>;
};

export { SetterProvider };
