import auth0 from 'auth0-js';
import jwt_decode from 'jwt-decode';

declare const rudderanalytics: any;

const DOMAIN = process?.env?.NEXT_PUBLIC_AUTH0_DOMAIN || '';
const CLIENT_ID = process?.env?.NEXT_PUBLIC_AUTH0_CLIENT_ID || '';
const AUDIENCE = process?.env?.NEXT_PUBLIC_AUTH_AUDIENCE || '';
const ROOT = process?.env?.NEXT_PUBLIC_ROOT;

export const auth = new auth0.WebAuth({
  domain: DOMAIN,
  clientID: CLIENT_ID,
  audience: AUDIENCE,
  scope: 'openid profile email',
});

export const loginWithSocial = (type: string) => {
  return new Promise<string>((resolve, reject) => {
    auth.popup.authorize(
      {
        domain: DOMAIN,
        clientId: CLIENT_ID,
        scope: 'openid profile email',
        redirectUri: `${ROOT}login/callback`,
        connection: type,
        responseType: 'token id_token',
        audience: AUDIENCE,
      },
      (err: auth0.Auth0Error | null, res: auth0.Auth0Result) => {
        if (err) {
          reject(err);
        } else {
          if (res.idToken) {
            decodeTokensendRudder(res.idToken);
            const decoded = jwt_decode(res.idToken) as any;
            resolve(decoded.email);
          }
        }
      }
    );
  });
};

export const checkSession = () => {
  return new Promise<void>((resolve, reject) => {
    auth.checkSession(
      {
        audience: AUDIENCE,
        scope: 'read:order write:order',
        responseType: 'token',
        redirectUri: `${ROOT}login/callback`,
      },
      function (err: any, authResult: any) {
        if (err) {
          reject();
        } else {
          resolve();
        }
      }
    );
  });
};

export const CheckCookie = (accessToken: string) => {
  return new Promise<void>((resolve, reject) => {
    auth.client.userInfo(accessToken, function (error: any, response: any) {
      if (error) {
        reject();
      } else {
        resolve();
      }
    });
  });
};

export const getUserInfo = (accessToken: string) => {
  return new Promise<void>((resolve, reject) => {
    if (accessToken) {
      auth.client.userInfo(accessToken, function (error: any, response: any) {
        if (error) {
          reject();
        } else {
          resolve(response);
        }
      });
    } else {
      reject();
    }
  });
};

export const CheckSessionCookie = (accessToken?: string) => {
  return new Promise<void>((resolve, reject) => {
    auth.checkSession(
      {
        audience: AUDIENCE,
        responseType: 'token',
        redirectUri: `${ROOT}login/callback`,
      },
      function (err: any, authResult: any) {
        // Authentication tokens or error
        if (err) {
          if (accessToken) {
            getUserInfo(accessToken)
              .then((res) => {
                resolve(res);
              })
              .catch(() => {
                reject();
              });
          } else {
            reject();
          }
        } else {
          if (authResult.accessToken) {
            getUserInfo(authResult.accessToken)
              .then((res) => {
                resolve(res);
              })
              .catch(() => {
                reject();
              });
          } else {
            reject();
          }
        }
      }
    );
  });
};

export const decodeTokensendRudder = (idToken: string) => {
  const decoded = jwt_decode(idToken) as any;

  if (decoded) {
    rudderanalytics.identify(decoded.email, {
      first_name: decoded.given_name,
      last_name: decoded.family_name,
      email: decoded.email,
    });
  }
};

export const rudderStackIdentify = (
  firstName: string,
  lastName: string,
  email: string,
  phoneCode?: string,
  phoneNumber?: string
) => {
  rudderanalytics.identify(email, {
    first_name: firstName,
    last_name: lastName,
    email: email,
    phone_code: phoneCode,
    phone_number: phoneNumber,
  });
};

export const LogOut = () => {
  auth.logout({
    returnTo: ROOT,
    clientID: CLIENT_ID,
  });
};
