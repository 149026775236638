import { GImage } from '@/Components/g-image';
import React, { useState } from 'react';
import Link from 'next/link';
import classes from './gworld-menu.module.css';
import { useDevice } from '@/Lib/hooks/useDevice';

const gworlDomainName =
  process?.env?.NEXT_PUBLIC_GWORLD_DOMAIN_NAME ||
  'gworld.globalworkandtravel.com';

type Props = {
  className?: string;
};

const GWorldButton = ({ className }: Props) => {
  const [isActive, setIsActive] = useState(false);
  const { isMD } = useDevice();

  return (
    <div className={`flex md:relative ${className} ${classes.gworldMenu}`}>
      <div
        className={`cursor-pointer lg:px-0 lg:pl-5 px-1 lg:py-4.25 flex justify-center items-center h-full 
        ${classes.gworldDropdown}`}
        onClick={() => {
          setIsActive(!isActive);
        }}
        onMouseEnter={() => isMD && setIsActive(true)}
        onMouseLeave={() => isMD && setIsActive(false)}
      >
        <i className="icon-profile pr--0.5 text-xl lg:text-base" />
        <i className="icon-down text-3xs ml-1.5" />
        <div
          className={`flex flex-col w-48 absolute  top-[99%] right-0 shadow-lg justify-center items-start md:items-center -z-1 bg-white min-w-full md:min-w-min ${
            classes.gworldDropdownMenu
          } ${isActive ? classes.active : ''}`}
        >
          <div className="flex flex-1 flex-col pl-4 md:pl-0">
            <div className="w-24 my-5 mx-auto">
              <GImage
                path={
                  'https://dvna932ue33yp.cloudfront.net/img/logos/gworld-logo-blue.svg'
                }
                alt="gworld-logo"
              />
            </div>
            <a href={`https://${gworlDomainName}`} className="pb-2.5">
              <div className="gwat-menu__gworld__manage">
                <i className="icon-edit pr-2" />
                Manage my trip
              </div>
            </a>
            <Link href="/gworld">
              <a className="pb-2.5">
                <div className="gwat-menu__gworld__link">
                  <i className="icon-question pr-2" />
                  What is gWorld?
                </div>
              </a>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export { GWorldButton };
