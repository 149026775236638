import { useEffect, FC, useContext, useState, useRef } from 'react';

import HeaderMenuTop from '@/Components/header/header-menu-top';
import HeaderMenuMain from '@/Components/header/header-menu-main';
import useScrollPosition from '@react-hook/window-scroll';
import { useRouter } from 'next/router';
import { HeaderVisiblityContext } from 'layouts/main.layout';
import { CSSTransition } from 'react-transition-group';
import PromotionBanner from '@/Components/promotion-banner/promotion-banner';
import { useIsGAppUserAgent } from '@/Lib/hooks/useIsGAppUserAgent';
import { useDevice } from '@/Lib/hooks/useDevice';
import { useAtomValue } from 'jotai';
import { promotionsAtom } from '@/State/global/global.store';
import { useOffice } from '@/Lib/hooks/useOffice';
import { HeaderData } from '@/Lib/types/header';

type props = {
  className?: string;
  navigationData: any;
  headerData: HeaderData;
};

const Header: FC<props> = ({ navigationData, headerData }) => {
  const { isLG } = useDevice();
  const { isOpen } = useOffice();
  const router = useRouter();
  const headedrContext = useContext(HeaderVisiblityContext);
  const scrollY = useScrollPosition(60 /*fps*/);
  const { height: promoHeight } = useAtomValue(promotionsAtom);
  const [headerAndPromoHeight, setHeaderAndPromoHeight] = useState<number>(58);
  const isGApp = useIsGAppUserAgent();
  const headerRef = useRef<HTMLDivElement>(null);
  const [headerHeight, setHeaderHeight] = useState<number>(58);

  useEffect(() => {
    setHeaderAndPromoHeight(headerHeight + (promoHeight || 0));
  }, [headerHeight, promoHeight]);

  // NOTE: we render menu-top if office is open in mobile
  // so we should depend on the isOpen for updating the height
  useEffect(() => {
    setTimeout(() => {
      if (headerRef?.current) {
        setHeaderHeight(headerRef.current.getBoundingClientRect().height);
      }
    }, 250);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [headerRef.current, isLG, isOpen]);

  useEffect(() => {
    if (headedrContext) {
      if (router.route === '/[division]/[...trip]') {
        if (headedrContext.isHeaderVisible && scrollY > 250) {
          headedrContext.setIsHeaderVisible(false);
        }
        if (
          !headedrContext.isHeaderVisible &&
          scrollY < 250 - headerAndPromoHeight
        ) {
          headedrContext.setIsHeaderVisible(true);
        }
      } else {
        headedrContext.setIsHeaderVisible(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollY, headerAndPromoHeight]);

  return (
    <div
      style={{
        paddingTop: `${headerAndPromoHeight}px`,
        transition: 'padding 0.3s ease-in-out',
      }}
    >
      <CSSTransition
        in={headedrContext?.isHeaderVisible}
        timeout={250}
        classNames="header-fade"
      >
        <div
          className={
            'z-300 text-base text-black fixed top-0 left-0 right-0 bg-white '
          }
        >
          {!isGApp ? <PromotionBanner /> : null}
          <div ref={headerRef}>
            <HeaderMenuTop headerData={headerData} />
            <HeaderMenuMain
              navigationData={navigationData}
              headerHeight={headerHeight}
            />
          </div>
        </div>
      </CSSTransition>
    </div>
  );
};

export default Header;
