import { useCookies } from 'react-cookie';
import { useSetAtom } from 'jotai';
import { useEffect } from 'react';
import { userEmailAtom, userLoginStatusAtom } from '../global/global.store';
import { CheckSessionCookie } from '@/Lib/helpers/auth0';

const useSetUserLoginStatus = () => {
  const [cookies] = useCookies(['auth']);
  const setLoginStatus = useSetAtom(userLoginStatusAtom);
  const setUserEmail = useSetAtom(userEmailAtom);

  useEffect(() => {
    // this bypasses FPDC if opened in gApp
    if (typeof window !== 'undefined') {
      if (window.navigator.userAgent.includes('gApp')) {
        setLoginStatus(true);
        return;
      }
    }

    CheckSessionCookie(cookies?.auth?.accessToken)
      .then((res: any) => {
        setLoginStatus(true);
        setUserEmail(res.email);
      })
      .catch(() => {
        setLoginStatus(false);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookies['auth']]);
};

export { useSetUserLoginStatus };
