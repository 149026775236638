import WishlistMenu from '@/Components/header/wishlist-menu';
import CallButton from '@/Components/header/call-button/call-button';
import { useDevice } from '@/Lib/hooks/useDevice';
import { useOffice } from '@/Lib/hooks/useOffice';
import GworldMenu from '@/Components/header/gwat-menu/gworld-menu';
import { SocialProof } from '@/Components/header/social-proof/social-proof';
import { HeaderData } from '@/Lib/types/header';

type Props = {
  headerData: HeaderData;
};

const HeaderMenuTop = ({ headerData }: Props) => {
  const { isLG } = useDevice();
  const { isOpen } = useOffice();

  return (
    <div className="py-2.5 md:py-2">
      <div
        className={
          'flex bg-white items-center  max-w-6.75xl font-semibold mx-auto px-5 lg:px-10' +
          (isOpen ? ' justify-end' : ' justify-center')
        }
      >
        <div className="flex mr-auto flex-1-only"></div>
        <div className="place-self-center">
          <SocialProof headerData={headerData} />
        </div>
        {isOpen || isLG ? (
          <div className="flex flex-row justify-end items-center text-sm gap-x-3 ml-auto flex-1-only">
            <WishlistMenu className="h-full" />
            {isLG ? <CallButton /> : <GworldMenu />}
          </div>
        ) : (
          <div className="flex-1-only"></div>
        )}
      </div>
    </div>
  );
};

export default HeaderMenuTop;
